@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px !important;
  }
}

@media (min-width: 992px) {
  .m-65 {
    margin-top: 65px;
  }
}

.ant-select-selection-item h6 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 2px;
}

.searchitem {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
}

.fw-600 {
  font-weight: 600;
}

.Toastify__toast-container--top-right {
  top: 65px !important;
}
.flight-skeleton p,
.flight-skeleton li,
.flight-skeleton label,
.flight-skeleton input,
.flight-skeleton h5,
.flight-skeleton h2,
.flight-skeleton h3,
.flight-skeleton h4,
.flight-skeleton h6,
.flight-skeleton span,
.flight-skeleton .radio-tabbing-half input:checked ~ label,
.flight-skeleton .fareCardItem input:checked ~ label,
.flight-skeleton a,
.flight-skeleton .listing-page-right .listing-slick a.active-a span,
.flight-skeleton .route-price,
.flight-skeleton .route-strating,
.flight-skeleton .route-price label:after,
.flight-skeleton select,
.flight-skeleton .cunsume-pic:after,
.flight-skeleton .cunsume-pic::before {
  color: transparent !important;
  border-color: transparent !important;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.1) 8%,
    rgba(130, 130, 130, 0.2) 18%,
    rgba(130, 130, 130, 0.1) 33%
  );
  background-size: 800px 100px;
  animation: wavesquares 2s infinite ease-out;
}

.flight-skeleton .flight-skeleton-img {
  height: 40px;
  width: 40px;
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.1) 8%,
    rgba(130, 130, 130, 0.2) 18%,
    rgba(130, 130, 130, 0.1) 33%
  );
  background-size: 800px 100px;
  animation: wavesquares 2s infinite ease-out;
}
.flight-skeleton .radio-div-small-skeleton {
  height: 18px;
  width: 18px;
  border: 1px solid lightgray;
}
.flight-skeleton .timing-radio {
  border: 1px solid lightgray;
}
.flight-skeleton .route-price-col a span {
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.1) 8%,
    rgba(130, 130, 130, 0.2) 18%,
    rgba(130, 130, 130, 0.1) 33%
  );
  background-size: 800px 100px;
  animation: wavesquares 2s infinite ease-out;
}

@keyframes wavesquares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

h2 {
  font-size: 29px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-bottom: 30px;
}

h2 span {
  color: #08a34e;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  color: #323232;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

h5 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

body {
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  color: #404040;
  background: rgb(244, 244, 244);
}

ul {
  padding: 0px;
  margin: 0px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #404040;
}

a:hover {
  color: #02a14d;
}

.main-header {
  transition: 0.3s;
  z-index: 9999;
}
.main-header.new-header {
  transition: 0.3s;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  margin: 0 auto;
  backdrop-filter: blur(1px);
  justify-content: space-between;
  background: white;
  /* background: rgb(1 1 1 / 63%); */
  animation: headerscroll 0.4s forwards;
  box-shadow: 0 0 25px gray;
}

@keyframes headerscroll {
  from {
    top: -200px;
  }
  to {
    top: 0;
  }
}

header {
  background: transparent;
  /* background: rgb(1 1 1 / 63%); */
  padding: 7px;
  position: static;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  transition: 0.3s;
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a.mobile-size {
  display: none;
}

header a.fill-btn {
  background: rgb(230, 225, 61);
  background: linear-gradient(
    90deg,
    rgba(230, 225, 61, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
  transition: 0.2s;
}

header a.fill-btn:hover {
  color: #fff;
}

a.fill-btn {
  background: rgb(205, 200, 36);
  background: linear-gradient(
    90deg,
    rgba(205, 200, 36, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
  border-radius: 50px;
  padding: 9px 19px;
  font-size: 13px;
  font-weight: 500;
  display: inline-flex;
  gap: 10px;
  align-items: flex-end;
  cursor: pointer;
}

button.fill-btn {
  background: linear-gradient(
    90deg,
    rgba(205, 200, 36, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
  border-radius: 50px;
  padding: 9px 19px;
  font-size: 13px;
  font-weight: 500;
  display: inline-flex;
  gap: 10px;
  align-items: flex-end;
  cursor: pointer;
  border: none;
}

button.fill-btn-disabled {
  border: 1px solid #777;
  background: #ebebeb;
}

button.fill-btn:disabled {
  background: linear-gradient(
    90deg,
    rgb(214, 211, 129) 00%,
    rgb(91, 163, 124) 100%
  ) !important;
}

.fareupdate-btn-list {
  margin-top: 18px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.fareupdate-text {
  font-size: 15px;
}

.radio-tabbing {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.radio-tabbing-half {
  position: relative;
}

.radio-tabbing-half input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}

.radio-tabbing-half label {
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
  min-width: 109px;
  text-align: center;
}

.radio-tabbing-half span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: 1px solid #a3a3a3;
  border-radius: 50px;
}

.radio-tabbing-half input:checked ~ span {
  background: #cdffe4;
  border-color: #08a34e;
}

.radio-tabbing-half input:checked ~ label {
  color: #08a34e;
}

.banner-tabbing-top {
  display: flex;
  align-items: center;
  gap: 44px;
}

.select-section {
  display: flex;
  align-items: center;
}

.select-section select {
  border: none;
  color: #0a0a0a;
  font-size: 15px;
  margin: 0;
  line-height: normal;
  outline: none;
  height: 24px;
  cursor: pointer;
}

.place-field {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 21px 20px;
  position: relative;
  /* added for the switch */
  gap: 30px;
}

.multicity-destinations-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 67.188px;
  padding: 0 7px;
}

.multicity-destinations {
  cursor: pointer;
  display: flex;
  gap: 10px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 22px 20px;
  position: relative;
  width: 100%;
}

.multicity-label {
  font-size: 22px;
  font-weight: 600;
  color: #0a0a0a;
  margin: 0;
}

.multicity-via-label {
  font-size: 13px;
  color: #000;
}

.fill-btn.multicity-search-btn {
  display: flex;
  padding: 9px 19px;
  height: 66px;
  width: 66px;
  align-items: center;
  border-radius: 10px;
}

/* need to hide this border after add switch locations functionality */
.place-field:after {
  border-right: 1px dashed #000;
  content: "";
  height: 50px;
  position: absolute;
  left: 50%;
}

.place-field-dropdown {
  display: flex;
  align-items: center;
  gap: 25px;
  position: relative;
  cursor: pointer;
  /* width: 100%; */
  /* added for the switch */
  width: calc(100% - 65px);
  min-width: 50% !important;
}

.place-field-dropdown.place-field-3,
.place-field-dropdown.place-field-1 {
  gap: 15px;
  /* margin: 0px 15px; */
  min-width: 50%;
  /* margin-right: 20px; */
}

.place-field-dropdown.place-field-3 {
  min-width: 50%;
}

.place-field-dropdown.place-field-4 {
  gap: 15px;
}

.place-field-text p {
  margin: 0;
}

.dropdown-menus {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  display: none;
  z-index: 9;
}

.footmtoggle-open .dropdown-menus {
  display: block;
}

.dropdown-menus ul {
  border: 1px solid #e3e3e3;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #fff;
}

.dropdown-menus a {
  padding: 2px 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.banner-tabbing .row {
  margin: 28px 0px 21px 0px;
}

.banner-tabbing .row .col-sm-12,
.listing-page-top .row .col-sm-12 {
  padding: 0px 7px;
}

.banner-tabbing a.fill-btn {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 50px;
  padding: 13px 30px;
  background: rgb(205, 200, 36);
  background: linear-gradient(
    90deg,
    rgba(205, 200, 36, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
}

.banner-tabbing .tab-content {
  padding: 34px 50px 53px 50px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: -15px;
}

.navbar-banner-tab {
  display: none;
}
.navbar-banner-tab.show {
  display: block;
}

.banner-tabbing .nav-pills {
  background: rgb(255 250 70 / 59%);
  margin: 0px !important;
  border-radius: 10px;
  padding-bottom: 20px;
  backdrop-filter: blur(10px);
  position: relative;
  z-index: -1;
  display: flex;
  flex-wrap: nowrap;
}
.navbar-banner-tab .nav-pills {
  background: transparent;
  margin: 0px !important;
  display: flex;
  flex-wrap: nowrap;
}

.banner-tabbing .nav-pills .nav-link {
  transition: 0.2s;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  padding-bottom: 0;
}

.navbar-banner-tab .nav-pills .nav-link {
  transition: 0.2s;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  padding-bottom: 0;
}

.navbar-banner-tab .nav-pills .nav-link img,
.banner-tabbing .nav-pills .nav-link img {
  filter: grayscale(1);
}

.navbar-banner-tab .nav-pills .nav-link:hover img,
.navbar-banner-tab .nav-pills .nav-link.active img,
.banner-tabbing .nav-pills .nav-link:hover img,
.banner-tabbing .nav-pills .nav-link.active img {
  filter: grayscale(0);
}

.banner-tabbing .nav-pills .nav-link,
.banner-tabbing .nav-pills .nav-link.active {
  font-size: 16px;
  color: #000;
  background-color: transparent;
  padding: 10px 30px 6px 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 0px;
  height: 100%;
  position: relative;
}

.navbar-banner-tab .nav-pills .nav-link,
.navbar-banner-tab .nav-pills .nav-link.active {
  font-size: 16px;
  color: black;
  background-color: transparent;
  padding: 8px 20px 4px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 0px;
  height: 100%;
  position: relative;
}

.navbar-banner-tab .nav-pills .nav-link::after {
  position: absolute;
  content: " ";
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 0%;
}

.navbar-banner-tab .nav-pills .nav-link.active::after {
  background: black;
  width: 100%;
}

.banner-tabbing .nav-pills .nav-link:hover,
.banner-tabbing .nav-pills .nav-link.active {
  font-weight: 700;
  border-color: #000;
  padding: 7px 30px 9px 30px;
}

.navbar-banner-tab .nav-pills .nav-link:hover,
.navbar-banner-tab .nav-pills .nav-link.active {
  font-weight: 700;
  padding: 5px 20px 7px 20px;
}

.banner-tabbing .nav-pills .nav-link:after {
  content: "";
  border-right: 1px dashed #000;
  height: 23px;
  position: absolute;
  right: 0;
}

button#pills-package-tab:after {
  display: none;
}

.banner-section {
  position: relative;
}

.banner-pic img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-tabbing {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 155px;
  position: relative;
  z-index: 9;
  padding-bottom: 109px;
}

span.field-top {
  position: absolute;
  top: -10px;
  left: 25px;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  padding: 1px 7px;
  color: #999999;
}

span.field-top.field-right {
  left: 56%;
}

.listing-page-top span.field-top.field-right {
  left: 52%;
}

.place-field-text h3 {
  margin-bottom: 3px;
}

.exclusive-offer {
  padding: 45px 0px 57px 0px;
}

.exclusive-offer h2 {
  text-align: center;
}

.exclusive-offer .nav-pills {
  justify-content: center;
  margin-bottom: 35px !important;
}

.exclusive-offer .nav-pills .nav-link {
  border-radius: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  border: 1px solid transparent;
  padding: 7px 18px;
}

.exclusive-offer .nav-pills .nav-link.active {
  background: transparent;
  border-color: #000;
  min-width: 124px;
}

.exclusive-offer .row {
  row-gap: 30px;
}

.exclusive-offer .row > * {
  padding: 0px 15px;
}

.offer-card {
  display: flex;
  background: #ffecec;
  border-radius: 10px;
  border: 1px solid rgb(0 0 0 / 20%);
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px 4px 20px;
  height: 100%;
  cursor: pointer;
}

.offer-card h3 {
  margin: 12px 0px 16px 0px;
}

.offer-card-text span {
  font-size: 16px;
}

.red-bg-liner {
  background: rgb(249, 230, 228);
  background: linear-gradient(
    90deg,
    rgba(249, 230, 228, 1) 00%,
    rgba(249, 230, 228, 0) 100%
  );
}

.blue-bg {
  background: rgb(234, 241, 255);
  background: linear-gradient(
    90deg,
    rgba(234, 241, 255, 1) 00%,
    rgba(239, 246, 255, 1) 100%
  );
}

.green-bg {
  background-color: #e7fff3;
}

.red-light-bg {
  background-color: #f5f0f0;
}

.offer-card-text span.red-span {
  color: #ff4f4f;
}

.offer-card-text span.blue-span {
  color: #488ad8;
}

.offer-card-text span.green-span {
  color: #227950;
}

p.cupon-code {
  margin: 0;
  background-color: #227950;
  color: #fff;
  padding: 1px 9px;
  border-radius: 5px;
  border: 1px dashed #fff;
  line-height: 18px;
  display: inline-block;
}

p.cupon-code span {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.offer-card img {
  mix-blend-mode: darken;
}

.destination-section h2 {
  text-align: center;
}

.Destinations-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.Destinations-card-pic img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
}

.Destinations-card:hover img {
  transform: scale(1.2);
}

.Destinations-card-body {
  position: absolute;
  left: 16px;
  bottom: 8px;
}

.Destinations-card:before {
  content: "";
  background: rgb(0 0 0 / 60%);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 00%,
    rgba(0, 0, 0, 30%) 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Destinations-card-body p {
  margin-bottom: 7px;
}

.Destinations-card,
.Destinations-card h4 {
  color: #fff;
}

.Destinations-card h4 {
  font-size: 18px;
}

.weekend-deals {
  padding: 45px 0px 60px 0px;
}

.weekend-deals h2 {
  margin-bottom: 12px;
}

p.heading-bottom {
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 400;
}

.weekend-card-pic {
  border-radius: 15px;
  overflow: hidden;
}

.weekend-card {
  cursor: pointer;
}

.weekend-card-pic:hover img {
  transform: scale(1.2);
}

.weekend-card-pic img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
}

.weekend-card h5 {
  margin-bottom: 0px;
  font-size: 16px;
}

.weekend-card h5 a {
  color: #000;
}

span.location {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

span.price {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin: 11px 0px 7px 0px;
}

span.old-price {
  font-size: 15px;
  font-weight: 300;
  text-decoration: line-through;
  margin-left: 6px;
  color: #404040;
}

.weekend-card p {
  margin: 0;
}

.weekend-card p.review {
  margin: 11px 0px 8px 0px;
}

span.discount {
  font-weight: 500;
  color: #fff;
  background-color: #227950;
  border-radius: 5px;
  padding: 2px 7px;
  margin-top: 11px;
  display: inline-block;
}

span.discount.available {
  background-color: #fddb32;
  color: #000;
}

.book-section h2 {
  text-align: center;
}

.book-card {
  background: #fffee0;
  border-radius: 10px;
  padding: 11px 20px;
  border: 1px solid #e6e13c;
  height: 100%;
}

.book-card p {
  font-size: 15px;
  font-weight: 300;
  margin: 0px;
}

.book-card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
}

.listing {
  padding: 50px 0px 30px 0px;
}

.listing h5 {
  line-height: normal;
  margin-bottom: 5px;
}

.listing ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
}

.listing a {
  padding: 1px 5px 1px 0px;
  display: inline-block;
}

.listing li {
  padding-right: 5px;
}

.social-text.text-underline strong {
  font-weight: 400;
  text-decoration: underline;
}

footer {
  background-color: #f3f3f3;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  padding-top: 23px;
  padding-bottom: 22.5px;
  margin-bottom: 32.5px;
}

.footer-top span {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.footer-top a {
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 9px;
}

footer li a {
  padding: 3px 0px;
  display: inline-block;
}

footer a {
  font-size: 13px;
  font-weight: 400;
  padding: 0px;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0px;
  margin-top: 50px;
  border-top: 1px solid rgb(0 0 0 / 10%);
}

.footer-bottom p {
  margin: 0px;
  color: #000;
}

.footer-bottom img {
  mix-blend-mode: multiply;
  max-width: 100%;
}

.sidebar-title {
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  padding-bottom: 17px;
  margin-bottom: 19px;
}

.sidebar-title h5 {
  margin: 0;
  color: #227950;
  font-size: 18px;
}

.sidebar-content h5 {
  font-size: 16px;
  color: #0a0a0a;
  margin-bottom: 9px;
}

.sidebar-content span {
  display: block;
}

.sidebar-content {
  display: flex;
  justify-content: space-between;
}

.sidebar-content span,
.sidebar-content label {
  font-size: 14px;
  font-weight: 400;
  color: #232323;
}

.sidebar-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
}

.sidebar-field {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 11px;
}

.sidebar-content span {
  margin-bottom: 11px;
}

.sidebar-field input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.sidebar-field label {
  cursor: pointer;
}

.sider-bar-depar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timimg-departure {
  position: relative;
}

.timing-radio {
  text-align: center;
  border-radius: 11px;
  border: 1px solid rgb(0 0 0 / 58%);
  padding: 5px 13px;
}

.timimg-departure input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
}

.timimg-departure p {
  font-weight: 500;
  margin: 0;
}

.timimg-departure span {
  font-size: 11px;
  color: #232323;
  font-weight: 300;
}

.timimg-departure input:checked ~ .timing-radio {
  border-color: #227950;
  background: #e5faf0;
}

.sidebar-timing h5 {
  font-size: 16px;
  color: #0a0a0a;
  margin-bottom: 20px;
}

.sidebar-content {
  padding-bottom: 27px;
}

.sidebar-timing {
  padding-bottom: 30px;
}

.multicity-tabbing ul.nav-pills {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0;
  overflow-x: auto;
  flex-wrap: nowrap;
  background: white;
}

.multicity-tabbing ul.nav-pills li.nav-item {
  border-right: 1px solid lightgray;
}

.multicity-tabbing ul.nav-pills .nav-link {
  color: black;
  border-radius: 0;
  padding: 8px 0;
  /* min-width: max-content; */
}

.multicity-tabbing ul.nav-pills .nav-link {
  color: black;
  border-radius: 0;
  padding: 8px 0;
  /* min-width: max-content; */
}

.multicity-tabbing ul.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: rgb(205, 255, 228);
  border: 1px solid;
  border-color: rgb(8, 163, 78);
  color: rgb(8, 163, 78);
}

.listing-sidebar {
  border-radius: 10px;
  border: 1px solid rgb(0 0 0 / 16%);
  padding: 20px 15px 10px 15px;
  background-color: #fff;
  /* max-height: 100vh; */
  max-height: calc(100vh - 94px);
  overflow-y: auto;
  scrollbar-width: none;
}

.listing-page-right ul {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 8px;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.multicity-filter-label {
  font-size: 16px;
  color: #0a0a0a;
  margin-bottom: 9px;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.listing-page-right .listing-slick a {
  border: 1px solid rgb(0 0 0 / 25%);
  display: inline-block;
  border-radius: 6px;
  padding: 5px 8px;
  background-color: #fff;
  text-align: center;
  min-height: 55px;
  min-width: 100px;
  width: 100%;
}

.listing-page-right .listing-slick a span {
  font-size: 16px;
  display: block;
}

.listing-page-right .listing-slick a.active-a span {
  font-weight: 600;
  color: #227950;
}

.listing-page-right .listing-slick a.active-a {
  border: 2px solid #227950;
}

.listing-page-right .owl-nav {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0px !important;
}

.listing-page-right button.owl-next {
  background-image: url(./assets/images/next-arrow.png) !important;
  background-position: center !important;
  width: 30px;
  height: 30px;
  margin: 0px !important;
}

.listing-page-right button.owl-next span {
  font-size: 0px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent !important;
}

.listing-page-right button.owl-prev {
  display: none !important;
}

.fight-detail-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0px 22px 0px;
}

.fight-detail-top h5 {
  color: #595959;
  margin: 0;
}

.fight-detail-top label {
  font-size: 14px;
  color: #000;
}

.fight-detail-top select {
  font-size: 14px;
  border: 0;
  outline: 0;
  background: transparent;
  color: #000;
}

.fight-detail-top-round-trip {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 25px 0px 22px 0px;
}

.fight-detail-top-round-trip h5 {
  color: #595959;
  margin: 0;
}

.fight-detail-top-round-trip label {
  font-size: 14px;
  color: #000;
}

.fight-detail-top-round-trip select {
  font-size: 14px;
  border: 0;
  outline: 0;
  background: transparent;
  color: #000;
}

.flight-route {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  padding: 11px 20px;
  margin-bottom: 18px;
}

/* LATEST CHANGE */
.fight-name span {
  max-width: 110px;
}
.combo-fight-name span {
  max-width: 130px;
}

.fight-name {
  display: flex;
  align-items: center;
  gap: 7px;
}

.fight-name h5 {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.fight-name p {
  margin: 0;
  color: #404040;
  font-weight: 300;
}

.fight-name img {
  height: 40px;
  width: 40px;
}

.route-timing {
  display: flex;
  align-items: center;
  gap: 8px;
}

.route-strating {
  font-size: 19px;
  font-weight: 500;
  color: #000;
}

.route-strating span {
  font-size: 13px;
  font-weight: 300;
  color: #404040;
}

.route-strating p {
  font-size: 12px;
  color: #404040;
  font-weight: 400;
  margin: 0;
}

.cunsume-time p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.cunsume-time span {
  display: block;
  color: #227950;
  font-size: 12px;
  font-weight: 400;
}

.cunsume-time {
  text-align: center;
}

.cunsume-pic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cunsume-pic:after,
.cunsume-pic::before {
  content: "";
  border-top: 1px dotted rgb(0 0 0 / 40%);
  width: 20px;
}

.flight-route-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.route-price {
  font-size: 18px;
  font-weight: 600;
  color: #227950;
  text-align: center;
}

.route-price span {
  display: block;
  font-size: 12px;
  color: #404040;
  font-weight: 400;
}

.flight-route a.fill-btn {
  font-size: 16px;
  color: #fff;
  min-width: 126px;
  padding: 10px;
  justify-content: center;
}

.listing-page {
  padding-bottom: 80px;
}

.listing-page-top .row {
  margin: 18px 0px 0px 0px;
  max-width: 1076px;
}

.listing-page-top {
  background: #fff;
  padding: 17px 0px 22px 0px;
  margin-bottom: 32px;
  margin-top: 67px;
}

.listing-page-top h3,
.listing-page-top .select-button label,
.listing-page-top
  .ant-select-selection-item
  .location-field-option
  .city-option {
  font-size: 16px;
  font-weight: 600;
  color: #0a0a0a;
  margin: 0;
}

.listing-page-top .place-field {
  padding: 13px 20px;
}

.list-top-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

.list-top-right .place-field {
  width: calc(100% - 66px);
}

.list-top-right a.fill-btn {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
}

li.next-process a {
  padding: 0;
  border: 0;
  background: transparent;
}

.listing-row .flight-route {
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 20px 20px 20px;
  margin-bottom: 15px;
}

.listing-row .flight-route .cunsume-pic {
  height: 23px;
}

.flight-route-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.listing-row .flight-route .route-timing {
  justify-content: space-between;
  width: 100%;
  padding: 15px 0px 7px 0px;
}

.indicatior {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #daebff;
  border: 1px solid #c7daf1;
  border-radius: 10px 10px 0px 0px;
  padding: 8px 10px;
  margin-bottom: 19px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.indicatior span {
  display: flex;
  align-items: center;
  gap: 7px;
}

.indicatior h5 {
  color: #232323;
  margin: 0;
}

.indicatior p {
  color: #595959;
  margin: 0px;
}

.about-section {
  padding: 90px 0px 94px 0px;
}

.about-section .row {
  align-items: center;
}

.about-pic img {
  max-width: 100%;
  border-radius: 10px;
}

.possible-section h2 {
  margin-bottom: 23px;
  text-align: center;
  line-height: 36px;
}

.possible-section p {
  max-width: 935px;
  text-align: center;
  margin: 0 auto;
}

.possible-section ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  padding: 27px 0px 68px 0px;
}

.possible-section ul img {
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.about-feature-half {
  background: #e5f0eb;
  border-radius: 30px;
  padding: 46px 23px 60px 23px;
  max-width: 346px;
  text-align: center;
}

.about-feature-half-1 {
  margin-left: auto;
}

.about-feature-half h4 {
  margin: 18px 0px 13px 0px;
  font-size: 19px;
  font-weight: 500;
}

.about-feature-half p {
  font-size: 14px;
  font-weight: 300;
}

.possible-section {
  padding-bottom: 130px;
}

.Booking-page {
  padding: 50px 0px 130px 0px;
}

.booking-sidebar .sidebar-right {
  text-align: right;
}

.booking-sidebar .sidebar-content span {
  margin-bottom: 9px;
}

.sidebar-content.siderbar-total {
  padding-bottom: 0px;
}

span.full-text {
  font-size: 11px;
  font-weight: 300;
  display: block;
  line-height: 14px;
}

span.full-text strong {
  font-weight: 500;
}

.booking-sidebar a.fill-btn {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 50px;
  margin-top: 41px;
  padding: 0px 15px;
}

.listing-sidebar.booking-sidebar {
  padding: 25px 23px 27px 23px;
  position: sticky;
  top: 0;
  /* edit by jyotindra */
  /* margin-top: 56px; */
}

.booking-page-right h2 {
  margin-bottom: 36px;
}

.fligt-confirm,
.traveller-detail {
  padding: 15px 25px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  margin-bottom: 18px;
}

.flight-choose {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.flight-indcator {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 25px;
}

.flight-indcator h5 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-right: 5px;
}

.flight-indcator a {
  font-weight: 600;
  color: #fff;
  background: #488ad8;
  border-radius: 5px;
  padding: 3px 10px;
  min-width: 86px;
  text-align: center;
}

.flight-indcator span {
  display: flex;
  align-items: center;
  gap: 15px;
}

.flight-indcator p {
  font-size: 13px;
  font-weight: 500;
  color: #909090;
  margin: 0;
}

.flight-choose {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.flight-choose .fight-name a {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 7px 12px;
  min-width: 96px;
  margin-left: 2px;
}

.flight-class span {
  font-weight: 500;
  color: #000;
  font-size: 13px;
}

.flight-class span strong {
  color: #227950;
  font-weight: 500;
}

.flight-duration span {
  font-weight: 500;
  color: #000;
  font-size: 13px;
}

.flight-duration span strong {
  color: #227950;
  font-weight: 500;
}

.fligt-confirm .route-timing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed rgb(0 0 0 / 20%);
  padding: 25px 0px 18px 0px;
}

.fligt-confirm .route-timing .cunsume-time p {
  font-weight: 400;
  color: #2d2d2d;
}

.fligt-confirm .cunsume-time p strong {
  font-weight: 500;
  color: #227950;
}

.fligt-confirm .route-strating {
  font-size: 21px;
}

.fligt-confirm .route-timing p {
  font-size: 13px;
  font-weight: 300;
}

.fligt-confirm .route-timing strong {
  font-weight: 500;
}

.route-strating.route-arrival {
  text-align: right;
}

.flight-review-card {
  border-bottom: 1px dashed rgb(0 0 0 / 20%);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.fligt-parcel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0px 13px 0px;
  max-width: 90%;
}

.flight-code {
  background: #fffdb4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
}

.fligt-parcel span {
  margin-left: 5px;
}

.flight-code span,
.fligt-parcel span {
  font-size: 13px;
  color: #141414;
}

.flight-code span strong,
.fligt-parcel strong {
  font-weight: 500;
}

.flight-code a {
  font-weight: 600;
  color: #227950;
  text-transform: capitalize;
}

.traveller-detail {
  padding: 26px 25px 41px 25px;
}

span.note {
  font-size: 13px;
  font-weight: 400;
  background: #ffe5e5;
  border-radius: 50px;
  padding: 5px 18px;
  color: #141414;
  display: inline-block;
}

span.note strong {
  font-weight: 500;
}

.traveller-detail p {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  /* margin: 20px 0px 9px 0px; */
  display: flex;
  align-items: center;
  gap: 5px;
}

.traveller-detail h5 {
  font-size: 20px;
  font-weight: 500;
}

a.add-more {
  font-size: 14px;
  font-weight: 500;
  color: #227950;
  display: block;
  padding: 9px 0px 22px 0px;
}

.traveller-detail form h5 {
  font-size: 16px;
  margin: 0;
}

.traveller-detail .field-set {
  display: flex;
  /* align-items: center; */
  gap: 25px;
  padding-top: 13px;
}

.traveller-detail .field-set .fields {
  width: 100%;
}

.traveller-detail .field-set .fields input,
.traveller-detail .field-set .fields select {
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(0 0 0 / 30%);
  height: 35px;
  padding: 8px 13px;
}

.traveller-detail .field-set label {
  display: block;
  margin-bottom: 2px;
}

.traveller-detail label {
  font-size: 14px;
  font-weight: 400;
}

.chaeckbox {
  padding: 14px 0px 25px 0px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.chaeckbox input {
  width: 18px;
  height: 19px;
}

span.agree-note {
  display: block;
  font-size: 14px;
  border-top: 1px solid rgb(0 0 0 / 20%);
  padding: 24px 0px 33px 0px;
}

span.agree-note a {
  color: #ff4f4f;
  text-decoration: underline;
}

.traveller-detail a.fill-btn {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  min-width: 297px;
  text-align: center;
  line-height: 50px;
  justify-content: center;
  padding: 0px 10px;
}

a.Mobile-size {
  display: none;
}

.flight-mobile .route-price {
  display: none;
}

.filter-toggle {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid rgb(0 0 0 / 16%);
  background: #fff;
  padding: 16px 20px;
  display: none;
}

.mobile-timimg {
  display: none;
}

/*CHANGE Commented out because now its handle by usestate */
/* .tabcontent-mt.tabiingcontent,
.tabcontent-ow.tabiingcontent {
  display: none;
} */

.place-field-text p {
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  color: #404040;
}

.select-dropdown li label span {
  font-size: 13px;
  font-weight: normal;
  display: block;
}

.custom-select {
  /* position: relative; */
  width: 100%;
  max-width: 100%;
  font-size: 1.15rem;
  color: #000;
}

.select-button {
  width: 100%;
  font-size: 13px;
  background-color: #fff;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.selected-value {
  text-align: left;
}

.select-dropdown {
  position: absolute;
  list-style: none;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  max-height: 250px;
  overflow-y: auto;
  min-width: 210px;
  transition: 0.5s ease;

  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
}

.select-dropdown:focus-within {
  box-shadow: 0 10px 25px rgba(94, 108, 233, 0.6);
}

.select-dropdown li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.select-dropdown li label {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
  display: block;
  gap: 1rem;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.select-dropdown::-webkit-scrollbar {
  width: 7px;
}

.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}

.select-dropdown li:hover,
.select-dropdown input:checked ~ label {
  background-color: #f2f2f2;
}

.select-dropdown input:focus ~ label {
  background-color: #dfdfdf;
}

.select-dropdown input[type="radio"] {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* interactivity */

.custom-select.active .arrow {
  transform: rotate(180deg);
}

.select-dropdown.showdropdown {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
  z-index: 9;
}

.select-button label {
  font-size: 24px;
  font-weight: 700;
  color: #323232;
  text-align: left;
  line-height: normal;
}

.select-button label span {
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  color: #404040;
  display: block;
}

.select-button input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.main-account,
.login-account {
  padding: 150px 0px 80px 0px;
}

.account-page {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.account-page a.fill-btn {
  font-size: 15px;
  color: #fff;
  padding: 11px 30px;
  background: rgb(0, 163, 77);
  background: linear-gradient(
    270deg,
    rgba(0, 163, 77, 1) 0%,
    rgba(230, 225, 61, 1) 100%
  );
  justify-content: center;
  gap: 10px;
  display: flex;
  align-items: center;
}

.account-page button.fill-btn {
  font-size: 15px;
  color: #fff;
  padding: 11px 30px;
  background: linear-gradient(
    270deg,
    rgba(0, 163, 77, 1) 0%,
    rgba(230, 225, 61, 1) 100%
  );
  width: 100%;
  justify-content: center;
  gap: 10px;
  display: flex;
  align-items: center;
}

.account-page h2 {
  margin-bottom: 30px;
}

.account-page h2 span {
  font-weight: 400;
  color: #b9d540;
}

a.account-logo {
  display: none;
  margin-bottom: 32px;
}

.both-btn {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.login-form {
  text-align: center;
}

.login-form .field {
  background: #fff;
  border-radius: 50px;
  padding: 11px 25px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.login-form .field input {
  background: transparent;
  border: none;
  width: 100%;
  outline: none;
  color: #000;
  font-size: 15px;
  font-weight: 400;
}

a.forget-btn {
  font-weight: 400;
  font-size: 15px;
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-top: 7px;
}

a.forget-btn-disabled {
  opacity: 0.3;
  color: black;
}

a.forget-btn-disabled:hover {
  opacity: 0.3;
  color: black;
}

a.new-user {
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  width: 100%;
}

.login-form .login-with {
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 17px;
}

.login-form .login-with:after,
.login-form .login-with:before {
  content: "";
  border-top: 1px solid #ffffff;
  width: 110px;
  height: 1px;
}

.login-form a.fill-btn {
  margin: 14px 0px 11px 0px;
}

.account-icons a {
  margin: 0px 5px;
}

a.new-user span {
  text-decoration: underline;
}

.fareCardItem {
  position: relative;
}

.fareCardItem label {
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
  min-width: 109px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.fareCardItem input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
}

.fareCardItem span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: 1px solid #a3a3a3;
  border-radius: 50px;
}

/* Changed because now its handle dyanmic  */
/* .fareCardItem input:checked ~ label {
  color: #08a34e;
} */
/* .fareCardItem input:checked ~ span {
  background: #cdffe4;
  border-color: #08a34e;
} */
.listing-page-top .extra-discount {
  padding-top: 20px;
}

.listing-page-top .select-fare {
  width: auto;
  min-width: auto;
}

.listing-page-top .select-fare h5 {
  margin: 0px;
}

.dscount-text label span {
  display: block;
  font-weight: 400;
}

.makeFlex.gap8.hrtlCenter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.extra-discount {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
}

.select-fare a {
  background: rgb(205, 200, 36);
  background: linear-gradient(
    90deg,
    rgba(205, 200, 36, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
}

.select-fare h5 {
  display: inline-block;
}

.select-fare {
  width: 160px;
  min-width: 160px;
}

.select-ppoup-list {
  display: flex;
  align-items: center;
  gap: 5px;
}

.select-ppoup-list .fareCardItem label {
  min-width: 50px;
}

.select-ppoup-top p {
  margin-bottom: 10px;
}

.select-ppoup-top h4 {
  margin-bottom: 5px;
}

.select-ppoup {
  position: absolute;
  left: 0;
  right: 0;
  top: 156px;
  background: #fff;
  z-index: 99;
  max-width: 90%;
  margin: 0 auto;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 11%);
  max-width: 840px !important;
  /* padding: 50px; */
  /* display: none; */
}

.select-section-open .select-ppoup {
  display: block;
}

.select-section h5 {
  color: #0a0a0a;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
  font-weight: 400;
}

.select-ppoup-middle {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  justify-content: space-between;
}

.popup-btn {
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 0px;
  cursor: pointer;
  gap: 20px;
}

.popup-btn a {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 50px;
  padding: 8px 30px;
  background: rgb(205, 200, 36);
  background: linear-gradient(
    90deg,
    rgba(205, 200, 36, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
  display: inline-block;
}

.cloe-btn {
  text-align: right;
}

.cloe-btn:after {
  content: "+";
  display: inline-block;
  transform: rotate(45deg);
  font-size: 30px;
  font-weight: 300;
}

/* .input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
} */

.custom-select input[type="date"] {
  width: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none !important;
  opacity: 0;
}

.custom-select input::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  width: 100%;
  height: 51px;
  cursor: pointer;
}

.custom-select input:-moz-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  width: 100%;
  height: 51px;
  cursor: pointer;
}

.custom-select input::-ms-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  width: 100%;
  height: 51px;
  cursor: pointer;
}

.slick-slide {
  margin: 0px 18px 0px 0px;
}

.slick-slide img {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-slide {
  transition: all ease-in-out 0.3s;
}

/* Slider */
.slick-list {
  overflow: hidden;
  position: relative;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-initialized .slick-slide {
  display: block;
}

.flight-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.flight-tabing {
  border-bottom: 0.5px solid #dcdcdc;
  background-color: #fafbfc;
  padding: 17px 20px;
  box-shadow: inset 0 2px 9px 0 rgba(0, 0, 0, 0.08);
  display: none;
}

.flight-tabing.flight-outer-open {
  display: block;
}

span.toggle-flight {
  cursor: pointer;
  color: #227950;
  margin-top: 7px;
}

.flight-detail th {
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
  color: #000;
}

.flight-cancel td {
  padding: 10px 0px;
}

.flight-cancel th span {
  display: block;
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
}

.flight-cancel table {
  border: 1px solid #dfdfdf;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-top: 15px;
  width: 90%;
}

.flight-cancel table td,
.flight-cancel table th {
  border: 1px solid #dfdfdf;
  padding: 10px 15px;
}

.flight-detail h5 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 500;
}

.flight-cancel span {
  margin-left: 15px;
}

.flight-cancel {
  border: 1px solid #dfdfdf;
  font-size: 14px;
  font-weight: 300;
  color: #232323;
  padding-bottom: 20px;
  overflow-x: auto;
}

.flight-summary {
  border: 1px solid #dfdfdf;
  padding-bottom: 20px;
}

/* LATEST CHANGES */
.flight-summary table {
  margin-left: 15px;
}

.flight-summary td {
  padding-top: 10px;
}

.flight-tabing .nav-pills .nav-link {
  border-radius: 50px;
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
  min-width: 109px;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  border: 1px solid #a3a3a3;
  color: #404040;
}

.flight-tabing .nav-pills .nav-link.active {
  background: #cdffe4;
  border-color: #08a34e;
  color: #08a34e;
}

.select-div {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  /* added for the switch */
  width: calc(100% - 65px);
}

.select-control {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/************************************
***************15/05/2024************
*************************************/
.listing-page .row {
  align-items: flex-start;
}

.listing-page .row .col-lg-3.col-md-5.col-sm-12 {
  position: sticky;
  top: 80px;
}

.listing-slick.slider .slick-next {
  display: none;
  right: 0;
}

.route-price label {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #0ea64b;
  position: relative;
  margin-right: 5px;
}

.route-price label:after {
  content: "";
  background: #0ea64b;
  position: absolute;
  left: 2px;
  right: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 2px;
  opacity: 0;
}

.route-price-col .route-price:hover input[type="radio"] + label::after {
  opacity: 1;
}

.route-price-col .route-price {
  position: relative;
  cursor: pointer;
  padding-bottom: 5px;
  text-align: left;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 5px;
}

.route-price input {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.route-price input:checked ~ :after {
  opacity: 1;
}

a.view-detail {
  background: #ebebeb;
  border: 1px solid #777;
  color: #000;
  border-radius: 30px;
  padding: 2px 10px;
  display: inline-block;
  margin-top: 12px;
  white-space: nowrap;
  cursor: pointer;
}

a.view-detail:hover {
  background: #333;
  border: 1px solid #000;
  color: #fff;
}

a.view-detail::after {
  /* content: "+"; */
  margin-left: 5px;
  font-weight: 600;
  font-size: 17px;
  line-height: 7px;
  vertical-align: middle;
}

a.view-detail > .plus-button {
  margin-left: 5px;
  font-weight: 600;
  font-size: 17px;
  line-height: 7px;
  vertical-align: middle;
}

.flight-route-right,
.flight-route {
  align-items: flex-start;
}

.flight-tabing-2,
.flight-tabing-3 {
  border-bottom: 0.5px solid #dcdcdc;
  background-color: #fafbfc;
  padding: 17px 20px;
  box-shadow: inset 0 2px 9px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  display: none;
  border: 0;
}

.flight-tabing-2.flight-outer-open,
.flight-tabing-3.flight-outer-open {
  display: block;
}

.flight-tabing-2 .nav-pills .nav-link,
.flight-tabing-3 .nav-pills .nav-link {
  border-radius: 0px;
  font-size: 12px;
  padding: 0px 2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  color: #404040;
}

.flight-tabing-2 .nav-pills .nav-link.active,
.flight-tabing-3 .nav-pills .nav-link.active {
  color: #08a34e;
  border-bottom: 1px solid #08a34e;
}

.flight-tabing-2 .nav-pills .nav-link {
  font-size: 15px;
  padding-bottom: 3px;
}

.flight-tabing-2 .flight-summary,
.flight-tabing-3 .flight-summary {
  border: 0;
  padding-bottom: 0px;
}

.flight-detail table {
  width: 100%;
  border: 1px solid #ebebeb;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.flight-tabing-2 .flight-summary table tr:last-child,
.flight-tabing-3 .flight-summary table tr:last-child {
  font-size: 16px;
  font-weight: 300;
  color: #000;
}

.flight-summary table:first-child tr {
  border: 0;
}

.detail-btn .flight-left {
  padding-bottom: 15px;
}

.flight-cancel table td,
.flight-cancel table th,
.flight-cancel table {
  border: 0;
}

.flight-detail table td,
.flight-detail table th {
  padding: 5px 10px;
  border: none;
}

.flight-cancel span.mention-tag {
  color: #0da54a;
  padding: 13px 0px;
  display: inline-block;
  font-size: 13px;
}

.flight-tabing-2 .flight-detail h5 {
  padding: 10px 5px;
}

.flight-tabing-2 .flight-table table th:first-child {
  min-width: 50px;
}

.flight-detail table th {
  background: #ebebeb;
  padding: 5px 10px;
}

.flight-tabing-2 .flight-cancel {
  border: none;
  padding: 0;
}

.flight-tabing-2 .flight-cancel span {
  margin-left: 0;
  font-size: 12px;
}

.flight-tabing-2 .flight-summary span,
.flight-tabing-3 .flight-summary span {
  padding: 10px 0 2px 0;
  display: inline-block;
  color: rgb(0 0 0 / 50%);
  font-weight: 500;
}

.flight-tabing-3.mobile-tab.flight-outer-open {
  display: none;
}

.flight-tabing-3 .fligt-confirm {
  border-radius: 5px;
  box-shadow: none;
  padding: 0;
}

.flight-tabing-3 {
  padding: 13px;
}

.flight-detail-tab-main .Detail-tab {
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.flight-tabing-3 .fligt-confirm .route-strating {
  font-size: 14px;
  width: 33.33%;
}

.flight-tabing-3 .fligt-confirm .route-timing p {
  font-size: 13px;
  font-weight: 300;
}

.flight-tabing-3 .cunsume-pic:after,
.flight-tabing-3 .cunsume-pic::before {
  width: 30px;
}

.flight-tabing-3 .flight-indcator {
  flex-wrap: wrap;
  gap: 12px;
}

.flight-tabing-3 .flight-indcator span {
  display: inline-block;
  width: 100%;
}

.flight-tabing-3 .flight-indcator h5 {
  font-size: 16px;
  display: inline-block;
}

.flight-tabing-3 .flight-choose .fight-name a {
  padding: 4px;
  min-width: 80px;
}

.flight-tabing-3 .flight-choose {
  align-items: center;
}

.flight-tabing-3 .fight-name img {
  max-width: 30px;
  max-height: 30px;
}

.flight-tabing-3 .fight-name span {
  max-width: 86px;
}

.footer-fix .flight-mobile .route-price {
  display: inline-block;
  font-size: 15px;
}

.footer-fix .flight-mobile .route-timing {
  padding-top: 0;
}

.route-timing {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flight-flex .route-timing {
  gap: 20px;
}

.flight-choose .route-timing {
  padding: 0;
  align-items: flex-start;
  width: 50%;
}

.flight-choose {
  padding-bottom: 20px;
  align-items: flex-start;
}

.flight-class {
  text-align: right;
}

.flight-duration {
  text-align: right;
}

.footer-fix .flight-mobile {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 452px;
}

.footer-fix {
  display: flex;
  align-items: center;
  gap: 13px;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  background: #e1f5ea;
  z-index: 99;
  flex-wrap: wrap;
  padding: 15px 0px;
  font-size: 18px;
}

.footer-fix .fight-name p {
  font-size: 13px;
}

.footer-last {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer-last .price {
  font-size: 20px;
  font-weight: 600;
  color: #227950;
  text-align: center;
}

.footer-last a.fill-btn {
  font-size: 16px;
  color: #fff;
  min-width: 126px;
  padding: 10px;
  justify-content: center;
}

/***************************************
****************21-05-2024**************
*************************************/
.flight-left span span {
  color: #34ac42;
}

span.sr-number {
  font-weight: 600;
  color: #b1b1b1;
  padding-left: 16px;
}

.route-price a {
  font-size: 10px;
  line-height: normal;
  color: #8b8b8b;
  font-weight: 500;
  display: block;
}

.route-price a span {
  background: #f3f2c9;
  border: 1px solid #e6e13d;
  color: #000000;
  border-radius: 5px;
  padding: 0px 3px;
  display: inline-block;
  margin-top: 4px;
  white-space: nowrap;
  margin-right: 5px;
  font-size: 11px;
}

a.up-arrow img {
  transform: rotate(180deg);
  cursor: pointer;
}

a.down-arrow img {
  cursor: pointer;
}

.expand-toggle a {
  background: #cdffe4;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #08a34e;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.expand-toggle a:hover {
  background-color: #fff;
}

/* .route-price-col.menu-open .expand-toggle a.up-arrow {
  display: inline-block;
}
.route-price-col.menu-open .expand-toggle a.down-arrow {
  display: none;
}
.expand-toggle a.up-arrow {
  display: none;
} */
.route-price-col {
  overflow: hidden;
  position: relative;
  transition: 0.2s;
}

/* .route-price-col .route-price:nth-last-child(2) {
  display: none;
}
.route-price-col.menu-open .route-price:nth-last-child(2) {
  display: block;
} */
.flight-detail-tab .route-price-col {
  position: relative;
}

.route-price-col.menu-open {
  max-height: 100%;
}

.expand-toggle {
  text-align: center;
}

.flight-left span img {
  transform: rotate(30deg);
  max-width: 20px;
  margin-right: 5px;
}

.flight-left span {
  padding-top: 0;
  display: inline-block;
  white-space: normal;
}

.flight-left {
  padding-top: 10px;
}

.flight-route .flight-mobile {
  flex-wrap: wrap;
}

.flight-indcator span {
  gap: 8px;
  font-weight: 500;
  color: #909090;
}

.footer-fix .route-strating {
  text-align: center;
  font-size: 14px;
}

.fligt-confir {
  padding-top: 22px;
}

.flight-class span {
  display: block;
}

.flight-duration span {
  display: block;
}

.flight-class > strong {
  color: #227950;
  font-weight: 600;
  font-size: 14px;
}

.flight-duration > strong {
  color: #227950;
  font-weight: 600;
  font-size: 14px;
}

.require {
  text-align: center;
}

.require span {
  background: #cdffe4;
  padding: 5px 10px;
  border-radius: 15px;
  display: inline-block;
}

.fligt-confir {
  padding-top: 15px;
  border-top: 1px dashed rgb(0 0 0 / 20%);
  margin-top: 15px;
}

.flight-tabing-2 .fligt-confirm .route-timing,
.flight-tabing-3 .fligt-confirm .route-timing {
  border-bottom: 0px;
}

.flight-tabing-3 .fligt-confirm .route-timing {
  padding-bottom: 20px !important;
}

.flight-route-top {
  gap: 15px;
  align-items: flex-start;
}

.flight-left a.view-detail {
  padding: 2px 10px;
}

.listing-page.active .row .col-lg-3.col-md-5.col-sm-12 {
  display: none;
}

.listing-page.active .row .col-lg-9.col-md-7.col-sm-12 {
  width: 100%;
}

.filter-hide {
  float: left;
  background: #37ad42;
  border-radius: 5px;
  padding: 6px 10px;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.3s;
  position: sticky;
  top: 0;
}

.filter-hide img {
  filter: invert(1);
}

.filter-hide:hover {
  background: #cdffe4;
}

.filter-hide:hover img {
  filter: invert(0);
}

.timimg-departure p {
  font-size: 12px;
}

.timing-radio {
  padding: 3px;
}

.sider-bar-depar {
  gap: 6px;
}

.timimg-departure span {
  font-size: 10px;
}

.flight-route-right,
.flight-route {
  gap: 30px;
  padding: 20px;
  position: relative;
}

.flight-route .flight-mobile {
  flex-wrap: wrap;
  width: 50%;
}

.route-price-right {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.flight-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listing-page-right .flight-tabing-2 ul {
  padding: 7px;
}

.flight-detail-tab .route-timing {
  padding: 0px !important;
  letter-spacing: -0.2px;
}

.flight-detail-tab .flight-route {
  gap: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.listing-page.active .flight-route .flight-mobile,
.listing-page.active .route-price-right {
  width: 40%;
}

.flight-tabing-2 .fligt-confirm {
  box-shadow: none;
}

.flight-tabing-2 .fligt-confirm .route-strating {
  font-size: 14px;
}

.flight-detail-tab .fight-name {
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  line-height: normal;
  min-width: 60px;
  font-size: 11px;
  letter-spacing: -0.2px;
}

.flight-detail-tab .fight-name h5 {
  font-size: 14px;
}

.flight-detail-tab .flight-route-name {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.flight-detail-tab .cunsume-time p {
  font-size: 11px;
}

.flight-detail-tab .route-strating p {
  font-size: 11px;
}

.flight-detail-tab .route-strating {
  font-size: 16px;
}

.listing-row .flight-detail-tab .flight-route .cunsume-pic {
  height: 21px;
}

.flight-detail-tab .cunsume-pic:after,
.flight-detail-tab .cunsume-pic::before {
  width: 15px;
}

/*******************************************
**************20-06-2024*********************
*********************************************/

span.switch {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 0px 2px rgb(0 0 0 / 6%);
  width: 35px;
  height: 35px;
  z-index: 9;
  border-radius: 50%;
  cursor: pointer;
  background: white;
}

/* span.switch:before {
  content: "";
  border-right: 1px dashed #000;
  height: 50px;
  position: absolute;
} */
/* .place-field-dropdown.place-field-1 {
  min-width: 50%;
  border: none;
}
.col-lg-7 .place-field-dropdown {
  min-width: 50% !important;
  margin: 0px 15px;
}
.place-field.menu-active .place-field-dropdown.place-field-2 {
  order: -1;
} */
.login-modal .modal-body {
  height: 56px;
  border-radius: 30px;
  border: 1px solid rgb(64 64 64 / 40%);
  position: relative;
  display: flex;
  padding: 16px;
}

.login-modal .modal-body .loginCont__label {
  position: absolute;
  left: 11px;
  top: -11px;
  background-color: rgb(255, 255, 255);
  padding: 3px 8px;
  line-height: normal;
  font-size: 12px;
  font-weight: 400;
  color: #404040;
}

.login-modal .modal-body .loginCont__code {
  background: rgb(255, 255, 255);
  font-size: 17px;
  font-weight: 500;
  color: rgb(194, 194, 194);
  display: inline-flex;
  padding: 0px 5px 0px 20px;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
}

.login-modal .modal-body .loginCont__input {
  background: rgb(255, 255, 255);
  width: 100%;
  border: 0px;
  outline: 0px;
  line-height: normal;
  font-size: 16px;
  font-weight: 500;
  color: rgb(79, 82, 90);
  padding-left: 5px;
  border-radius: 0px 4px 4px 0px;
}

.modal-footer {
  justify-content: center;
}

.login-modal .modal-header {
  padding: 0px 0px 20px 0px;
  border: 0px;
}

.modal-header {
  padding-bottom: 0;
  border: 0px;
}

/* .modal-body {
  padding: 0;
} */

.modal-dialog {
  max-width: 550px;
}

.modal-content {
  border: none;
  padding: 0;
}

.login-modal .modal-content {
  width: 400px;
  height: 456px;
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.2) -5px 0px 9px 0px;
  background-color: rgb(255, 255, 255);
  padding: 50px 50px 30px;
  position: relative;
  border: none;
}

.cYwnDu {
  width: 100%;
  margin-top: 22px;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  line-height: normal;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 50px;
  padding: 13px 30px;
  background: rgb(205, 200, 36);
  background: linear-gradient(
    90deg,
    rgba(205, 200, 36, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
}

.cYwnDu:disabled {
  background: linear-gradient(
    90deg,
    rgb(214, 211, 129) 00%,
    rgb(91, 163, 124) 100%
  ) !important;
}

.jFZtLs {
  line-height: normal;
  font-size: 12px;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 0;
}

.jFZtLs a {
  color: rgb(0, 140, 255);
  text-decoration: none;
  cursor: pointer;
}

.half-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-left {
  width: 343px;
  height: 432px;
  border-radius: 20px 0px 0px 20px;
  flex-shrink: 0;
  overflow: hidden;
  padding: 22px 20px 30px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-image: url(./assets/images/offer-2.png);
  cursor: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.modal-left:before {
  content: "";
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.sc-dtInlm.eCzTrX {
  margin-top: auto;
  position: relative;
}

.login-modal .modal-dialog {
  display: flex;
  align-items: center;
  max-width: 745px;
  top: 50%;
  transform: translateY(-50%) !important;
  margin: 0 auto;
}

button.btn-close {
  position: absolute;
  right: 25px;
  top: 25px;
  font-size: 18px;
  z-index: 99;
}

.btn-edit {
  font-size: 22px;
  cursor: pointer;
  padding-bottom: 2px;
}

.gxPBXm {
  margin-bottom: 30px;
  margin-top: -22px;
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  gap: 10px;
  align-items: center;
}

.verifyOtpCont__otpFiled {
  height: 56px;
  border-radius: 4px;
  border: 1px solid rgb(64 64 64 / 40%);
  color: rgb(79, 82, 90);
  outline: 0px;
  text-align: center;
  padding: 0px;
  line-height: normal;
  font-size: 16px;
  font-weight: 500;
  width: 60px !important;
}

.verifyOtpCont__otpFiled:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.dtMloo .otpScreen__social > li {
  width: 94px;
  margin: 0px 0px 0px 10px;
  padding: 4px 14px 5px 12px;
  border-radius: 2px;
  border: 1px solid rgb(206, 206, 206);
  background-color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  gap: 5px;
}

.cPVbtY {
  padding: 10px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 6px 0px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  border: 1px solid rgb(239, 239, 239);
}

.dtMloo .otpScreen__social > li .gi-tooltip {
  display: none;
}

.dtMloo .otpScreen__social > li:hover .gi-tooltip {
  display: block;
}

.sc-eldPxv.dtMloo,
ul.otpScreen__social {
  display: flex;
  align-items: center;
}

.verifyOtpCont__message {
  min-height: 50px;
}

.rClTI {
  line-height: normal;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}

.verifyOtpCont__otpCont {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.fCDhNF {
  line-height: normal;
  font-size: 20px;
  font-weight: 600;
  color: rgb(79, 82, 90);
  margin-bottom: 5px;
  text-align: center;
}

.bsLsTf {
  line-height: normal;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin-bottom: 5px;
  text-align: center;
}

.rhNIm {
  line-height: normal;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 0;
  height: auto;
  padding: 10px;
}

.rhNIm span {
  background: #000;
  color: #fff;
  padding: 6px 10px;
  border: 1px solid #000;
}

span.empty {
  background: transparent;
  color: #000;
}

.login-modal .modal-content.open .model-inner-1 {
  display: none;
}

.modal-inner-2 {
  display: none;
}

.login-modal .modal-content.open .modal-inner-2 {
  display: block;
}

.menu li a,
.mobile-menu li a {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.menu,
.mobile-menu {
  background-color: #fff;
  border-radius: 10px;
  width: 380px;
  padding: 15px;
  position: absolute;
  right: 12px;
  z-index: 999;
  top: 46px;
  display: none;
  transition: 0.5s;
}

.menu a.fill-btn,
.mobile-menu a.fill-btn {
  justify-content: center;
  color: #fff;
}

p.bold {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
}

p.light {
  margin: 0;
  font-size: 12px;
  line-height: normal;
}

.menu li,
.mobile-menu li {
  padding: 7px 0px;
}

.menu li h6,
.mobile-menu li h6 {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 18px;
}

.btn-right:hover + .menu {
  display: block;
}

.btn-mobile-right:hover + .mobile-menu {
  display: block;
}

.container:hover .menu:hover {
  display: block;
}

.container:hover .mobile-menu:hover {
  display: block;
}

.btn-right {
  position: relative;
}

.btn-mobile-right {
  position: relative;
}

.prifile-deta a {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  background: transparent;
  border: 1px solid #a3a3a3;
  padding: 5px 10px;
  border-radius: 20px;
  text-decoration: none;
  color: #404040;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.prifile-deta .nav-tabs {
  border: none;
}

.prifile-deta a img {
  max-width: 20px;
}

.prifile-deta a.active {
  color: #08a34e;
  background: #cdffe4;
  border-color: #08a34e;
}

.prifile-deta li.nav-item {
  width: 100%;
}

/* .prifile-deta li {
  padding: 5px 0px;
} */

.prifile-deta {
  text-align: center;
  background: #fff;
  padding: 50px 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  position: sticky;
  top: 0;
}

.verified-step span {
  background: #16a94b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.verified-step span img {
  max-width: 12px;
}

a.basic-detail:before {
  content: "+";
  background: #000;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.verified-step a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
}

a.basic-detail {
  gap: 5px;
}

.verified-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verified-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ebebeb;
  padding: 15px;
  border-radius: 5px;
}

/* .process-bar {
  background: #ccc;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 6px;
}
.process-bar:before {
  content: "";
  background: #10a74c;
  height: 100%;
  position: absolute;
  width: 75%;
  border-radius: 5px 0px 0px 5px;
} */
.progress {
  background: #ccc;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 6px;
}

.progress-bar {
  background-color: #10a74c;
}

.profile-add-span {
  color: #08a34e;
  cursor: pointer;
}

.process h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
}

.process h6 span {
  font-weight: 500;
}

.process {
  width: 50%;
}

.profile-process {
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
}

.profile-detail tr {
  border-bottom: 1px solid rgb(0 0 0 / 20%);
}

.profile-detail tr:last-child {
  border: none;
}

.profile-detail td {
  padding: 10px 0px;
  font-size: 15px;
}

.w-40 {
  width: 40%;
}

.w-25 {
  width: 25%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-0 {
  gap: 0;
}

.profile-detail table {
  width: 100%;
}

.profile-detail table input,
.profile-detail table select,
.profile-detail table textarea {
  border: 0;
  outline: 0;
  padding: 0;
  background: transparent;
  font-size: 14px;
  color: #000;
}

.profile-detail table span {
  font-weight: 500;
}

.profile-detail {
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  padding: 20px;
  border-radius: 12px;
  margin-top: 25px;
  border: 1px solid #efefef;
  background: #fff;
}

.prfile-name h2 {
  margin-bottom: 5px;
  line-height: normal;
}

.prfile-name p {
  margin: 0;
}

.profile-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-wrap: wrap;
  gap: 15px;
}

.profile-heading a {
  background: #cdffe4;
  color: #08a34e;
  border: 1px solid #08a34e;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 73px;
  text-align: center;
  font-size: 14px;
}

.profile-page {
  padding: 50px 0px;
}

.profile-upload {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin: 0 auto;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.profile-upload input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.profile-upload span {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(205, 200, 36);
  background: linear-gradient(
    357deg,
    rgba(205, 200, 36, 1) 00%,
    rgba(0, 163, 77, 1) 100%
  );
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prifile-deta h5 {
  font-size: 18px;
  margin-top: 6px;
  margin-bottom: 0;
}

.profile-detail table img {
  border: 1px solid #00000040;
  border-radius: 50%;
  padding: 3px;
  margin-right: 13px;
}

.profile-detail table td div {
  display: flex;
}

.select-button {
  background-color: transparent;
}

.travel-form-detail span.close-btn:after {
  content: "+";
  display: inline-block;
  transform: rotate(45deg);
  font-size: 35px;
  float: right;
  cursor: pointer;
}

.travel-form-detail .nav-pills .nav-link {
  border-radius: 0px;
  font-size: 14px;
  padding: 3px 8px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  color: #404040;
}

.travel-form-detail .nav-pills .nav-link.active {
  color: #08a34e;
  border-bottom: 1px solid #08a34e;
  background: transparent;
}

a.disable {
  background: #ebebeb;
  border: 1px solid #777;
  color: #000;
  margin-right: 10px;
}

.travel-detail-btns {
  text-align: end;
}

.travel-detail-btns a {
  min-width: 90px;
}

/* .travel-form-detail { */
/* box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%); */
/* padding: 20px; */
/* border-radius: 12px; */
/* background-color: #fff; */
/* max-width: 550px; */
/* position: absolute; */
/* z-index: 99; */
/* left: 0; */
/* right: 0; */
/* top: 50%; */
/* transform: translateY(-50%); */
/* margin: 0 auto; */
/* display: none; */
/* } */
span.overlay {
  position: fixed;
  left: 0;
  right: 0;
  background: rgb(0 0 0 / 40%);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9;
  display: none;
  cursor: pointer;
}

.travel-form-detail .field-set {
  display: flex;
  /* align-items: center; */
  gap: 15px;
  padding-bottom: 13px;
}

.travel-form-detail .field {
  width: 50%;
}

.travel-form-detail .field input,
.travel-form-detail .field select {
  width: 100%;
  border-radius: 20px;
  border: 1px solid rgb(0 0 1 / 50%);
  padding: 5px 10px;
  outline: none;
}

.travel-form-detail label {
  font-size: 15px;
  margin-bottom: 5px;
}

.travel-form-detail .field label span {
  color: #c91919;
  font-size: 16px;
}

.basic-info {
  margin-bottom: 20px;
}

.passport-info {
  padding-top: 15px;
}

/* .travel-form {
  padding: 25px 0px;
} */
.travel-form h5 {
  margin-bottom: 3px;
}

.travel-form p {
  margin-bottom: 13px;
}

.add-travel.open span.overlay,
.add-travel.open .travel-form-detail {
  display: block;
}

.booking-timing {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.booking-timing.menu-active {
  flex-direction: column-reverse;
}

.fligt-confirm .booking-timing .route-strating {
  font-size: 21px;
  width: 33.33%;
}

.booking-timimg-main {
  background: whitesmoke;
  padding: 0px 13px;
  border-radius: 10px;
}

.terminal-change {
  border-left: 1px dashed #000;
  padding-left: 14px;
  margin: 0 AUTO;
  width: 100%;
  max-width: 80%;
}

.terminal-change span {
  color: #227950;
  font-weight: 500;
}

.terminal-change p {
  margin: 0;
}

.fare-rule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.flight-indcator {
  padding-bottom: 15px;
}

.radio-field {
  margin-top: 21px;
  display: flex;
  align-items: end;
  gap: 5px;
  /* width: 33.33%; */
}

.field-set.align-end {
  align-items: start;
}

.field-set.align-end .fields {
  width: 33.33%;
}

.radio-field .fields {
  position: relative;
  width: 100% !important;
  text-align: center;
}

.radio-field .fields input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.radio-field .fields label {
  border: 1px solid rgb(0 0 0 / 30%);
  padding: 7px 13px;
  margin-bottom: 0;
  border-radius: 5px;
}

.radio-field .fields input:checked ~ label {
  background: #cdffe4;
  border-color: #08a34e;
  color: #08a34e;
}

.child-form {
  padding-top: 15px;
  display: inline-block;
  width: 100%;
}

.w-33 {
  width: 33.33%;
}

/* .extra-form,
.child-form .form {
  display: none;
} */
/* .adult-form.menu-active .extra-form {
  display: block;
}
.child-form input:checked ~ .form {
  display: block;
} */
/* .child-form .form-1 {
  display: block;
} */
input.form-check {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 7px;
}

.birth-select {
  display: flex;
  align-items: center;
  width: 31%;
  gap: 5px;
  padding-bottom: 18px;
}

.form {
  border-bottom: 1px dashed rgb(0 0 0 / 20%);
}

span.agree-note {
  border-top: none;
}

.adult-form .add-more {
  padding: 10px 0px;
  border-bottom: 1px dashed rgb(0 0 0 / 20%);
}

span.note.book-note input {
  margin-right: 5px;
}

span.note.book-note {
  display: flex;
  align-items: center;
}

.select-state select {
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(0 0 0 / 30%);
  padding: 8px 13px;
  width: 100%;
  font-size: 14px;
}

.select-state label {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 4px;
}

.select-state .fields {
  width: 31%;
  padding: 10px 0px;
}

.select-state,
.Important-information {
  padding: 20px 25px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  margin-bottom: 18px;
}

.traveller-detail.travrller-btn {
  background: transparent;
  padding: 0;
  box-shadow: none;
  margin: 0;
}

.Important-information img {
  max-width: 18px;
  margin-right: 6px;
}

.Important-information li {
  list-style: disc;
}

.Important-information ul {
  padding-left: 22px;
}

.Important-information h4 {
  margin-bottom: 14px;
}

.Important-information ul {
  padding-left: 22px;
  padding-bottom: 11px;
}

.select-state span.input-span {
  display: flex;
  align-items: center;
}

.select-state span input {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.select-dropdown a label {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
  display: block;
  gap: 1rem;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.select-dropdown a label span {
  font-size: 13px;
  font-weight: normal;
  display: block;
}

.select-dropdown a {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.select-dropdown a:hover,
.select-dropdown input:checked ~ label {
  background-color: #f2f2f2;
  color: #000;
}

.drop-input {
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 20%);
  padding: 7px 10px;
  width: 100%;
}

body.modal-open,
.modal {
  padding: 0px !important;
}

div#exampleModal {
  padding: 0px !important;
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0px !important;
}

.loading-spinner-overlay {
  /* background: rgba(255, 255, 255, 0.8); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.loading-spinner {
  border: 3px solid rgba(0, 163, 77, 1);
  border-bottom: 3px solid #f3f3f3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.profile-email-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-verified {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 163, 77, 1);
}

.verify-email {
  font-size: 14px;
  color: #288fe9;
  cursor: pointer;
}

.email-verified svg {
  font-size: 20px;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-icon {
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
}

.loggedin-email {
  display: flex;
  align-items: center;
}

.traveller-icon {
  font-size: 20px;
  color: #08a34e;
  cursor: pointer;
}

.red-color {
  color: #fb5757;
}

.traveller-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

/* image upload css  */

.file-input {
  display: none;
}

.edit-button {
  padding: 5px;
  border: 4px solid white;
  position: absolute;
  bottom: 24px;
  right: -20px;
  background-color: lightgray;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
}

.min-height-150 {
  min-height: 150px;
}

.traveller-details-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 9px 0px;
}

.tarveller-error-message {
  color: red;
  font-size: 12px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.trip-detail {
  padding: 50px 0px;
}

.trip-detail-tabbing {
  margin: 0;
}

.trip-detail-tabbing .nav-pills {
  gap: 30px;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #ddd;
}

.trip-detail-tabbing .nav-pills .nav-link.active {
  border-color: #08a34e;
  color: #08a34e;
  background: transparent;
}

.trip-detail-tabbing .nav-pills .nav-link {
  border-bottom: 2px solid transparent;
  padding: 15px 0px;
  border-radius: 0px;
  font-size: 16px;
  text-align: center;
  color: #404040;
  font-weight: 500;
}

.trip-detail-tabbing table {
  text-align: center;
}

.sidebar-tab .sidebar-content {
  padding-bottom: 0;
  display: none;
  padding-top: 5px;
}

.sidebar-tab {
  padding-bottom: 10px;
}

.sidebar-tab h5 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  cursor: pointer;
}

.sidebar-tab h5::before {
  content: "+";
  width: 18px;
  display: flex;
  height: 18px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 2px;
  margin-right: 10px;
}

.sidebar-tab.menu-active h5::before {
  content: "-";
}

.sidebar-tab.menu-active .sidebar-content {
  /* edit by jyotindra */
  display: flex;
}

.route-timing .flight-choose,
.travel-detail-new .fligt-confirm .booking-timing .route-strating,
.travel-detail-new .cunsume-time {
  width: 25%;
  padding: 0;
}

.flight-indcator p {
  display: flex;
  align-items: center;
  gap: 10px;
}

.travel-detail-new .route-timing {
  padding: 0;
  padding-bottom: 10px;
}

.travel-detail-new .booking-timimg-main {
  background: transparent;
  padding: 10px 0px;
}

.travel-detail-new .booking-timimg-main:last-child .route-timing {
  padding: 0;
  border: 0;
}

.travel-detail-new .booking-timimg-main:last-child {
  padding: 0;
}

.travel-detail-new .booking-timimg-main:last-child .mobile-timimg {
  border: 0;
}

.travel-contact {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.traveller-list .sidebar-content {
  padding: 0;
}

.traveller-list {
  padding: 15px 25px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  width: 50%;
}

.traveller-contact-detail a {
  display: inline-block;
  width: 100%;
  padding: 5px 0px;
}

.traveller-contact-detail a img {
  max-width: 18px;
  margin-right: 10px;
}

.traveller-list h4 {
  margin-bottom: 18px;
}

/********************************************
****************18-07-2024*******************
********************************************/

.trip-detail-tabbing {
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  padding: 20px;
  border-radius: 12px;
  margin-top: 25px;
  border: 1px solid #efefef;
  background: #fff;
}

.booking-confirmationbar {
  padding: 15px 25px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 6%);
  margin-bottom: 48px;
  width: 100%;
  text-align: center;
}

.booking-confirmationbar h2 {
  margin: 0 0 5px 0;
  line-height: normal;
  color: #08a34e;
}

.booking-confirmationbar p {
  margin: 0;
  font-size: 15px;
}

.flight-indcator-id {
  text-align: center;
  padding-top: 10px;
}

.flight-indcator-id h5 {
  border: 1px solid #08a34e;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 8px;
  margin: 0 0 10px 0;
}

.flight-indcator-id h5 strong {
  color: #08a34e;
  font-weight: 600;
}

.trip-details-flight-indicator {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.filter-fareCardItem label {
  min-width: unset !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.trip-detail-tabbing .nav-pills .nav-link svg {
  height: 23px;
}

.trip-detail-tabbing .nav-pills .nav-link.active svg path {
  stroke: #08a34e;
}

.upcoming-trips img {
  max-height: 140px;
}

.upcoming-trips {
  text-align: center;
}

.upcoming-trips a.fill-btn {
  font-size: 16px;
  padding: 12px 40px;
  color: #fff;
}

.trip-detail-tabbing th {
  text-transform: capitalize;
}

td.red-text {
  color: #c31f1f;
}

.trip-detail-tabbing th,
.trip-detail-tabbing td {
  text-align: left;
  padding: 11px 10px !important;
  font-size: 15px;
}

.triproute-timing .route-strating {
  font-size: 16px;

  text-align: left;
}

/* .triproute-timing .route-strating h4 {
  text-transform: uppercase;
  font-size: 15px;
  color: #777;
  font-weight: 500;
} */

.triproute-timing .route-strating h4 {
  font-size: 17px;
  color: #08a34e;
  font-weight: 500;
}

.triproute-timing h5 {
  border: 0;
  padding: 0;
  margin: 0;
}

.btn-trip-complte {
  font-size: 16px;
}

strong.trip-complete-btn {
  color: #08a34e;
  font-weight: 400;
  margin-right: 20px;
}

.btn-trip-complte a.fill-btn {
  font-size: 16px;
  color: #fff;
}

.btn-trip-complte {
  width: 25%;
  text-align: center;
  min-width: 160px;
}

a.btn-text svg {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 4px;
}

.btn-trip-complte-invoice {
  padding-top: 12px;
  font-size: 15px;
}

.route-timing.triproute-timing {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  gap: 30px;
}

.route-timing.triproute-timing:last-child {
  border: 0;
}

.farerule-model {
  max-width: 991px;
}
.farerule-model .modal-body {
  max-height: 362px;
}
.farerule-model .modal-body .body-content {
  min-width: 959px;
}

.DateRangePicker,
.SingleDatePicker {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.DateRangePicker > div,
.SingleDatePicker > div {
  height: 100%;
}

.DateRangePicker .DateRangePickerInput,
.SingleDatePicker .SingleDatePickerInput {
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  border: none;
}

.DateRangePicker .DateRangePickerInput .DateInput,
.SingleDatePicker .SingleDatePickerInput .DateInput {
  width: 100%;
  height: 100%;
  background: transparent;
}

.DateRangePicker .DateRangePickerInput .DateInput input,
.SingleDatePicker .SingleDatePickerInput .DateInput input {
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.DateRangePickerInput_arrow {
  display: none !important;
}

.SingleDatePicker_picker,
.DateRangePicker_picker {
  z-index: 10 !important;
}

/* .SingleDatePicker_picker {
  left: -10% !important;
} */

.CalendarDay__selected_span {
  background: rgb(204 255 158 / 60%) !important;
  color: black !important;
  border: 1px double #84cb84 !important;
}

.CalendarDay__selected {
  background: rgba(0, 163, 77, 1) !important;
  color: white !important;
}

.CalendarDay__selected:hover {
  background: rgb(3 217 104) !important;
  color: white !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgb(179 236 127) !important;
}

.DayPicker_transitionContainer {
  height: 305px !important;
}

.CalendarMonth_caption {
  padding-bottom: 50px !important;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 20px !important;
}

/************** CHANGES *************/
.slick-slide {
  /* padding: 0 10px; */
  margin: 0;
}

.ant-select-selector {
  border: none !important;
}

.ant-select-focused:where(
    .css-dev-only-do-not-override-kghr11
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-focused:where(.css-kghr11).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
}

/* .ant-select-focused:where(
    .css-dev-only-do-not-override-kghr11
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
} */

.ant-select-focused:where(
    .css-dev-only-do-not-override-d2lrxs
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
}

/* .ant-select-focused:where(.css-kghr11).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
} */

.ant-select-focused:where(.css-d2lrxs).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
}

.error-border {
  border-color: red !important;
}

.error-label {
  color: red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.selected-flight-border {
  border: 1px solid rgba(0, 163, 77, 1);
}

.location-field-option {
  margin-bottom: 10px;
}

.ant-select-selection-item .location-field-option .city-option {
  font-size: 24px;
  font-weight: 700;
  color: #323232;
  line-height: 30px;
}

.ant-select-selection-item .location-field-option .code-option {
  display: none;
}

.ant-select-selection-item .location-field-option .airport-name-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 165px;
  font-size: 13px;
  color: #000;
}

.code-option {
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: #747474;
}

.city-option {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 5px;
  color: #000000;
}

.airport-name-option {
  font-size: 12px;
  margin-bottom: 3px;
  line-height: 14px;
  color: #9b9b9b;
  text-wrap: wrap;
}

.option-group-title {
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: rgb(158, 158, 158);
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-select-dropdown,
:where(.css-d2lrxs).ant-select-dropdown {
  width: 300px !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-select-single.ant-select-open
  .ant-select-selection-item
  .location-field-option
  .option-field
  .city-option,
:where(.css-dev-only-do-not-override-d2lrxs).ant-select-single.ant-select-open
  .ant-select-selection-item
  .location-field-option
  .airport-name-option,
:where(.css-d2lrxs).ant-select-single.ant-select-open
  .ant-select-selection-item
  .location-field-option
  .option-field
  .city-option,
:where(.css-d2lrxs).ant-select-single.ant-select-open
  .ant-select-selection-item
  .location-field-option
  .airport-name-option {
  color: rgba(0, 0, 0, 0.25) !important;
}

.option-field {
  display: flex;
  justify-content: space-between;
  gap: 5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.select-div .select-control:focus-within ~ .select-city-label {
  opacity: 0 !important;
}

.select-div .select-control:focus-within {
  opacity: 1 !important;
}
.add-remove-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
  align-items: center;
}
.h-0 {
  height: 0 !important;
}

.min-h-52 {
  min-height: 52px;
}

.min-h-39 {
  min-height: 39px;
}

.z-index-0 {
  z-index: 0 !important;
}

/* .ant-picker-panels {
  display: flex;
  flex-direction: column;
}

.ant-picker-date-panel-container {
  margin: 0 auto !important;
}

.ant-picker-range-arrow {
  left: 50% !important;
} */

.multicity-accordian .accordion-header {
  display: flex;
  background: rgb(205, 255, 228);
  align-items: center;
  outline: 1px solid rgb(8, 163, 78);
}
.multicity-accordian .accordion-collapse .accordion-body {
  padding: 0;
  max-height: 500px;
  overflow: scroll;
}
.multicity-accordian .accordion-header .accordian-flight-container {
  margin: 10px 0;
  padding: 0 5px;
  flex: 1 1 0%;
  border-right: 1px solid mediumseagreen;
}
.multicity-accordian
  .accordion-header
  .accordian-flight-container
  .accordian-flight {
  display: flex;
  align-items: center;
}
.multicity-accordian
  .accordion-header
  .accordian-flight-container
  .accordian-flight
  .accordian-flight-image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.multicity-accordian
  .accordion-header
  .accordian-flight-container
  .accordian-flight
  .place-code {
  display: flex;
  align-items: center;
}
.multicity-accordian
  .accordion-header
  .accordian-flight-container
  .accordian-flight
  .place-code
  p {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 16px;
}
.multicity-accordian
  .accordion-header
  .accordian-flight-container
  .flight-price {
  font-size: 16px;
  color: rgb(34, 121, 80);
  font-weight: 600;
  margin-top: 5px;
  margin-left: 5px;
}
.multicity-accordian .accordion-header .accordian-flight-cost {
  margin: 10px;
}
.multicity-accordian .accordion-header .accordian-flight-cost .cost-total {
  font-size: 12px;
  font-weight: bold;
  margin: 0px 0px 6px;
}
.multicity-accordian .accordion-header .accordian-flight-cost .cost {
  font-size: 18px;
  color: rgb(34, 121, 80);
  font-weight: 600;
  margin: 0px;
}
.multicity-accordian .accordion-header .accordian-flight-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.multicity-accordian .accordion-header .accordian-flight-btn p {
  font-size: 10px;
  margin: 0px;
  cursor: pointer;
  color: black;
}
.multicity-accordian .accordion-header .accordion-button {
  width: 50px !important;
  background: transparent;
  box-shadow: none;
  transform: rotate(180deg);
}
.reviewbooking-something-wrong {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.filter-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.219);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.filter-loader {
  display: inline-block;
  position: relative;
  width: fit-content;
  height: 110px;
  width: 170px;
}

.filter-loader div {
  position: absolute;
  top: 33px;
  width: 40px;
  margin-right: 50px;
  height: 40px;
  border-radius: 50%;
  background: #0aa54b;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.filter-loader div:nth-child(1) {
  left: 8px;
  animation: flip1 0.6s infinite;
}

.filter-loader div:nth-child(2) {
  left: 8px;
  animation: flip2 0.6s infinite;
}

.filter-loader div:nth-child(3) {
  left: 65px;
  animation: flip2 0.6s infinite;
}

.filter-loader div:nth-child(4) {
  left: 122px;
  animation: flip3 0.6s infinite;
}

@keyframes flip1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flip3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes flip2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(57px, 0);
  }
}

/* RESPONSIVE CSS */

@media (max-width: 1199px) {
  .offer-card-pic img {
    max-width: 148px;
  }

  .place-field-dropdown.place-field-3,
  .place-field-dropdown.place-field-1 {
    min-width: 230px;
  }

  .place-field-dropdown.place-field-3 {
    min-width: 150px;
  }

  h2 {
    font-size: 27px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 21px;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
  }

  .banner-tabbing {
    padding-left: 20px;
    padding-right: 20px;
  }

  .place-field {
    padding: 21px 16px;
  }

  .listing-page-top .place-field {
    padding: 10px 10px;
  }

  .listing-page-top .row {
    row-gap: 20px;
  }

  .flight-route {
    padding: 11px 10px;
    flex-wrap: wrap;
    gap: 20px;
  }

  .route-price-right,
  .flight-route .flight-mobile {
    width: 100%;
  }

  .route-timing {
    gap: 5px;
  }

  .cunsume-pic:after,
  .cunsume-pic::before {
    width: 23px;
  }

  .listing-page-right ul {
    overflow-x: auto;
  }

  .listing-page-right ul a {
    min-width: 105px;
  }

  li.next-process a {
    min-width: auto;
  }

  .listing-sidebar {
    padding: 20px 10px 10px 10px;
  }

  .timing-radio {
    padding: 5px 6px;
  }

  .fligt-parcel {
    max-width: 100%;
    padding-top: 8px;
  }

  .flight-code a {
    width: 60px;
    text-align: right;
  }

  .flight-code span {
    width: calc(100% - 60px);
  }

  .fligt-parcel {
    flex-wrap: wrap;
    gap: 6px;
  }

  .flight-indcator {
    padding-bottom: 15px;
    flex-wrap: wrap;
    gap: 6px;
  }

  .Booking-page {
    padding-bottom: 80px;
  }

  .about-section {
    padding: 90px 0px 54px 0px;
  }

  .possible-section ul {
    padding: 20px 0px 48px 0px;
  }

  .possible-section {
    padding-bottom: 80px;
  }

  .place-field-dropdown label {
    font-size: 21px;
    font-weight: 600;
  }

  .date-picker-display {
    font-size: 21px;
    font-weight: 600;
  }

  .makeFlex.gap8.hrtlCenter {
    overflow-x: auto;
  }

  .btn-edit {
    top: 80px;
  }
}

@media (max-width: 991px) {
  .farerule-model {
    max-width: 95%;
  }

  .farerule-model .modal-body {
    max-height: 362px;
    overflow-x: scroll;
    scrollbar-width: thin;
  }
  .farerule-model .modal-body .body-content {
    min-width: 966px;
  }

  .root.mt-65 {
    margin-top: 0px !important;
  }
  .main-header {
    position: relative !important;
  }
  .banner-tabbing .row {
    margin: 28px 0px 21px 0px;
    gap: 30px;
  }

  .navbar-banner-tab {
    display: none !important;
  }

  .place-field-dropdown.place-field-3,
  .place-field-dropdown.place-field-1 {
    min-width: 50%;
    /* margin-right: 50px; */
  }

  .banner-tabbing {
    padding-left: 20px;
    padding-right: 20px;
  }

  .flight-route {
    flex-direction: column;
    padding: 11px 20px;
  }

  .fight-detail-top span {
    display: none;
  }

  .fight-detail-top-round-trip span {
    display: none;
  }

  .cunsume-pic:after,
  .cunsume-pic::before {
    width: 50px;
  }

  .route-timing {
    justify-content: space-between;
    width: 100%;
  }

  .flight-indcator h5 {
    font-size: 18px;
  }

  .flight-choose {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .flight-class {
    width: 100%;
    text-align: right;
  }

  .flight-duration {
    width: 100%;
    text-align: right;
  }

  .flight-choose .fight-name a {
    padding: 0px 7px;
    min-width: 88px;
    margin-top: auto;
  }

  .flight-route {
    flex-direction: column;
    padding: 11px 20px;
    margin-bottom: 16px;
  }

  .flight-route-right .route-price {
    display: none;
  }

  .flight-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .flight-route-right {
    width: 100%;
  }

  .route-timing {
    justify-content: space-between;
    width: 100%;
    padding: 15px 0px;
  }

  .timing-radio {
    padding: 3px;
  }

  .sidebar-content {
    padding-bottom: 12px;
  }

  .sidebar-timing h5 {
    margin-bottom: 12px;
  }

  .listing-page-right h2 {
    line-height: 28px;
    font-size: 24px;
    margin-bottom: 18px;
  }

  .listing-page {
    padding-bottom: 30px;
  }

  .traveller-detail .field-set {
    gap: 15px;
  }

  .traveller-detail .field-set {
    gap: 0;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-left: -10px;
    margin-right: -10px;
    row-gap: 15px;
  }

  .traveller-detail .field-set .fields {
    width: 50%;
    padding: 0px 10px;
  }

  .traveller-detail .field-set .fields.email-field {
    width: 100%;
  }

  .radio-field {
    margin-top: 0px;
    width: 50%;
  }

  .chaeckbox {
    padding: 17px 0px 20px 0px;
  }

  span.agree-note {
    padding: 17px 0px 21px 0px;
  }

  a.add-more {
    padding: 8px 0px 20px 0px;
  }

  .fligt-confirm .cunsume-pic:after,
  .fligt-confirm .cunsume-pic::before {
    width: 0;
  }

  .fligt-confirm .cunsume-time p {
    display: none;
  }

  .mobile-timimg .cunsume-time p,
  .mobile-timimg {
    display: block;
    font-size: 14px;
  }

  .mobile-timimg .cunsume-time p strong {
    font-weight: 600;
  }

  .fligt-confirm .route-timing {
    border-bottom: 0;
    padding-bottom: 9px;
  }

  .mobile-timimg {
    border-bottom: 1px dashed rgb(0 0 0 / 20%);
    padding-bottom: 13px;
  }

  .fligt-confirm {
    padding: 15px 15px 19px 15px;
  }

  .booking-page-right h2 {
    margin-bottom: 21px;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }

  .listing-sidebar.booking-sidebar {
    position: static;
    border-radius: 10px;
    width: 100%;
    height: auto;
    padding: 23px 25px 31px 25px;
  }

  .Booking-page {
    padding-bottom: 30px;
  }

  .about-section {
    padding: 90px 0px 34px 0px;
  }

  .possible-section ul {
    padding: 20px 0px 30px 0px;
  }

  .possible-section {
    padding-bottom: 30px;
  }

  .about-feature-half {
    padding: 30px 15px 40px 15px;
  }

  .select-ppoup-middle {
    flex-wrap: wrap;
    gap: 30px;
  }

  .listing-page-top span.field-top.field-right {
    left: 56%;
  }

  .flight-tabing-3.desktop-tab.flight-outer-open {
    display: none;
  }

  .flight-tabing-3.mobile-tab.flight-outer-open {
    display: block;
  }
}

@media (max-width: 767px) {
  /* .select-div.first-select-div {
    width: 100%;
  } */

  .select-div {
    width: calc(100% - 35px);
  }

  .ant-select-selection-item .location-field-option .city-option {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 3px;
  }

  .DayPicker_transitionContainer {
    height: 355px !important;
  }

  .DayPickerNavigation_svg__vertical {
    width: 24px !important;
  }
  .DateInput_fang {
    display: none;
  }

  a.Mobile-size {
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  a.logo img {
    max-width: 139px;
  }

  header a.fill-btn {
    display: none;
  }

  header button.fill-btn {
    display: none;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 17px;
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
  }

  .banner-tabbing {
    padding-top: 105px;
    padding-bottom: 60px;
  }

  .banner-tabbing .nav-pills {
    display: flex;
    flex-wrap: nowrap;
  }

  .banner-tabbing .nav-pills .nav-link,
  .banner-tabbing .nav-pills .nav-link.active {
    flex-direction: column;
    padding: 5px 8px;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
  }

  .banner-tabbing {
    padding-left: 10px;
    padding-right: 10px;
  }

  .banner-tabbing .nav-pills .nav-link:after {
    height: 45px;
  }

  .banner-tabbing .tab-content {
    padding: 24px 14px 50px 14px;
  }

  .radio-tabbing {
    gap: 5px;
  }

  .radio-tabbing-half label {
    min-width: auto;
  }

  .banner-tabbing-top {
    gap: 13px;
    flex-wrap: wrap;
  }

  .place-field-dropdown.place-field-2 .place-field-pic {
    display: none;
  }

  .place-field-pic {
    display: none;
  }

  .place-field {
    padding: 20px 12px;
  }

  .place-field-dropdown.place-field-1 {
    border-right: none;
    flex-direction: row-reverse;
    gap: 0;
  }

  .banner-tabbing .row .col-sm-12,
  .listing-page-top .row .col-sm-12 {
    padding: 0;
  }

  .place-field-dropdown.place-field-3,
  .place-field-dropdown.place-field-1,
  .place-field-dropdown.place-field-4 {
    gap: 6px;
  }

  .banner-tabbing a.fill-btn {
    justify-content: center;
    width: 100%;
  }

  .exclusive-offer {
    padding: 28px 0px;
  }

  .offer-card {
    padding-right: 0px;
    height: 154px;
  }

  .exclusive-offer .nav-pills {
    justify-content: left;
    margin-bottom: 18px !important;
    flex-wrap: nowrap;
  }

  .weekend-deals {
    padding-top: 38px;
  }

  .weekend-deals h2 {
    font-size: 22px;
  }

  .exclusive-offer .row {
    row-gap: 9px;
  }

  .book-section h2,
  .destination-section h2,
  .exclusive-offer h2 {
    text-align: left;
  }

  .book-section .col-sm-6 {
    width: 50%;
    padding: 0px 6px;
  }

  .book-section .row {
    row-gap: 10px;
  }

  .book-card h4 {
    margin: 7px 0px 4px 0px;
  }

  .book-card h4 br {
    display: none;
  }

  .book-card-top {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .listing {
    padding: 55px 0px 15px 0px;
  }

  .footer-top {
    padding-top: 27px;
    padding-bottom: 18px;
    margin-bottom: 9px;
    flex-direction: column;
    gap: 15px;
  }

  a.footer-logo {
    margin-bottom: 13px;
  }

  .footer-top a {
    align-items: flex-start;
  }

  .footer-bottom {
    flex-direction: column-reverse;
    gap: 4px;
    padding-top: 16px;
    margin-top: 12px;
  }

  .footmtogglep ul {
    display: none;
  }

  .listing .footmtoggle-open ul {
    display: flex;
  }

  .footmtoggle-open ul {
    display: inline-block;
  }

  .footmtoggle:after {
    content: "+";
    font-size: 24px;
    font-weight: 300;
  }

  .footmtoggle-open .footmtoggle:after {
    content: "-";
  }

  .footmtoggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .listing-page-top {
    display: none;
  }

  .route-timing {
    padding: 15px 0px 8px 0px;
  }

  .listing-page-right h2 {
    line-height: 26px;
    font-size: 20px;
  }

  .listing-page {
    padding-bottom: 0px;
  }

  .filter-toggle h5 {
    display: flex;
  }

  .filter-overlay {
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 999;
    border-radius: 0;
    height: 100vh;
    overflow-y: auto;
    transition: 0.5s;
    background: rgb(0 0 0 / 40%);
    width: 100%;
  }

  .listing-sidebar {
    width: 90%;
    border-radius: 0;
  }

  .filter-overlay.active {
    left: 0;
  }

  .timing-radio {
    padding: 5px 10px;
  }

  .sider-bar-depar {
    gap: 5px;
  }

  .listing-sidebar {
    padding: 10px 10px 10px 10px;
  }

  .flight-route {
    padding: 11px;
  }

  span.close-btn:before {
    content: "+";
    font-weight: 300;
    font-size: 30px;
    color: #000;
    transform: rotate(45deg);
    display: inline-block;
  }

  span.close-btn {
    display: inline-block;
    text-align: center;
    float: right;
    position: relative;
    z-index: 9;
  }

  .flight-indcator h5 {
    font-size: 16px;
  }

  .fligt-confirm .cunsume-pic {
    justify-content: center;
  }

  .fields.code-field {
    max-width: 100px;
  }

  .chaeckbox label {
    font-size: 12px;
  }

  .Booking-page .row {
    flex-direction: column-reverse;
  }

  .Booking-page {
    padding: 106px 0px 0px 0px;
  }

  .possible-section {
    padding-bottom: 0;
  }

  .about-feature-half {
    padding: 25px 10px;
    max-width: inherit;
    height: 100%;
  }

  .about-feature .row {
    row-gap: 20px;
  }

  .about-section .row {
    gap: 25px;
  }

  .mobile-none {
    display: none;
  }

  .container.max-width-100 {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .acount-page {
    padding: 100px 30px 0px 30px;
  }

  .exclusive-offer .nav-pills .nav-link {
    padding: 6px 18px;
    white-space: nowrap;
  }

  .traveller-detail a.fill-btn {
    min-width: auto;
    padding: 0px 10px;
    display: inline-block;
    width: 100%;
  }

  .fligt-confirm {
    padding: 10px;
  }

  .route-timing {
    gap: 0;
  }

  .place-field-dropdown.place-field-1 {
    min-width: 52%;
    /* margin-right: 37px; */
  }

  .possible-section ul {
    flex-wrap: wrap;
    gap: 10px;
  }

  .possible-section ul li {
    width: 48%;
  }

  .banner-tabbing li.nav-item {
    width: 33.33%;
  }

  .banner-tabbing .nav-pills .nav-link,
  .banner-tabbing .nav-pills .nav-link.active {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .place-field-dropdown label {
    font-size: 17px;
  }

  .main-account .container,
  .login-account .container {
    height: 100%;
    z-index: 99;
    position: relative;
  }

  .main-account,
  .login-account {
    padding: 0;
    height: 100vh;
  }

  .login-form {
    margin-top: auto;
    margin-bottom: auto;
  }

  .main-account {
    background-image: url(./assets/images/accout-bg.jpg);
    position: relative;
    background-position: center;
  }

  .login-account {
    background-image: url(./assets/images/login-bg.png);
    position: relative;
    background-position: center;
  }

  .account-page h3,
  /* a.forget-btn, */
  a.new-user,
  span.login-with {
    color: #fff;
  }

  .main-account:after,
  .login-account::after {
    content: "";
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(102, 102, 102, 0) 70%
    );
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 9;
  }

  .login-account::after {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(102, 102, 102, 0) 100%
    );
  }

  .account-page .both-btn {
    margin-top: auto;
    margin-bottom: 79px;
  }

  .account-page {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  a.account-logo {
    margin-top: 100px;
  }

  .account-page h2 {
    color: #fff;
  }

  .login-page a.account-logo {
    margin-bottom: auto;
  }

  .login-form .login-with {
    gap: 10px;
  }

  .login-form .login-with:after,
  .login-form .login-with:before {
    width: 100px;
  }

  a.account-logo {
    display: block;
  }

  .date-picker-display {
    font-size: 17px;
  }

  .extra-discount {
    flex-wrap: wrap;
  }

  .select-ppoup {
    max-width: 95%;
    top: 70px;
    padding: 25px 15px;
  }

  .select-ppoup-list {
    overflow-x: auto;
  }

  .select-ppoup-list .fareCardItem label {
    min-width: 45px;
    padding: 3px 10px;
  }

  .flight-detail {
    overflow-x: auto;
  }

  a.forget-btn-disabled {
    opacity: 0.5;
    /* color: white; */
  }

  a.forget-btn-disabled:hover {
    opacity: 0.5;
    /* color: white; */
  }

  .btn-edit {
    top: 54px;
  }
}

@media (max-width: 500px) {
  .banner-tabbing .nav-pills .nav-link:after {
    height: 75px;
  }

  /************** CHANGES *************/
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }

  .ant-picker-date-panel-container {
    margin: 0 auto !important;
  }

  .ant-picker-range-arrow {
    left: 50% !important;
  }

  /************** CHANGES *************/
}

@media (max-width: 479px) {
  .place-field-dropdown.place-field-1 {
    min-width: 54%;
    margin-right: 16px;
  }

  .place-field-dropdown.place-field-3 {
    min-width: 52%;
    margin-right: 20px;
  }
}

@media (max-width: 360px) {
  .exclusive-offer .nav-pills .nav-link {
    padding: 6px 12px;
  }

  .listing-page-right .item a {
    padding: 5px;
  }
}

/*************************************************
********************21-05-2024*********************
***************************************************/
@media (max-width: 1199px) {
  .flight-route {
    gap: 15px;
  }

  .flight-route-top {
    flex-wrap: wrap;
  }

  .timimg-departure p {
    font-size: 9px;
  }
}

@media (max-width: 991px) {
  .flight-tabing-3.desktop-tab.flight-outer-open {
    display: none;
  }

  .flight-tabing-3.mobile-tab.flight-outer-open {
    display: block;
  }

  .cunsume-pic:after,
  .cunsume-pic::before {
    width: 23px;
  }

  .flight-choose {
    flex-direction: row;
    gap: 10px;
  }

  .flight-tabing-2 .fligt-confirm .cunsume-pic:after,
  .flight-tabing-2 .fligt-confirm .cunsume-pic::before {
    width: 20px;
  }

  .footer-fix {
    display: none;
  }

  .flight-flex {
    gap: 10px;
  }

  .fight-detail .flight-route {
    padding: 11px;
  }

  .flight-tabing-2 .fligt-confirm .flight-choose {
    flex-direction: column;
  }

  .flight-tabing-2 .fligt-confirm .route-timing {
    border-bottom: 0px;
    width: 100%;
    justify-content: center;
  }

  .timimg-departure p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .listing-page .row .col-lg-4.col-md-5.col-sm-12 {
    position: unset;
  }

  .flight-tabing-2 .fligt-confirm .route-timing,
  .flight-tabing-2 .fligt-confir .route-timing {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    text-align: center;
  }

  .flight-tabing-2 .fligt-confirm .route-timing .route-strating.route-arrival,
  .flight-tabing-2 .fligt-confir .route-timing .route-strating.route-arrival {
    text-align: center;
  }

  .require span {
    border-radius: 30px;
    font-size: 10px;
  }

  .filter-hide {
    display: none;
  }

  .filter-toggle h5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  .filter-toggle {
    display: flex;
    padding: 0px;
    margin-top: 25px;
    margin-bottom: 26px;
  }

  .slick-list {
    overflow: hidden;
    position: relative;
    z-index: -1;
  }

  .slick-next:before {
    display: none;
  }

  .flight-flex {
    flex-wrap: wrap;
  }

  .route-price-right {
    flex-wrap: wrap;
    gap: 15px;
  }

  .flight-flex .route-timing {
    padding: 0;
  }

  .flight-choose .fight-name,
  .flight-choose .route-timing {
    width: 100%;
    justify-content: center;
  }

  .flight-class {
    text-align: center;
  }

  .flight-duration {
    text-align: center;
  }

  .flight-route-top {
    flex-wrap: wrap;
  }

  .flight-tabing-3 .fligt-confirm .route-strating {
    width: auto;
  }

  .flight-tabing-3 .fligt-confirm .route-timing {
    align-items: flex-start;
  }

  .flight-tabing-2 .fligt-confirm .route-timing {
    flex-wrap: nowrap;
  }

  .flight-tabing-2 .fligt-confirm .cunsume-pic:after,
  .flight-tabing-2 .fligt-confirm .cunsume-pic::before {
    width: 0;
  }

  .flight-detail-tab .route-price-col {
    width: 100%;
  }

  .flight-route a.fill-btn,
  .flight-route .detail-btn,
  .route-price-right .flight-btn {
    width: 100%;
    min-width: auto;
  }

  .listing-page .row .col-lg-3.col-md-5.col-sm-12 {
    position: static;
    top: 0;
  }
}

/****************************************
********************************************
*****************************************/
@media (max-width: 1199px) {
  .prifile-deta a {
    font-size: 12px;
    gap: 10px;
  }

  .birth-select {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .prifile-deta {
    padding: 20px 10px;
  }

  .profile-process {
    padding: 12px;
  }

  .verified-step {
    flex-wrap: wrap;
    gap: 10px;
  }

  .profile-detail {
    padding: 12px;
  }

  .travel-table td {
    min-width: 148px;
    vertical-align: middle;
  }

  .travel-table table span {
    display: inline-block;
  }

  .travel-table {
    overflow-x: auto;
  }

  .process {
    width: 100%;
  }

  .traveller-detail .field-set .fields input,
  .traveller-detail .field-set .fields select {
    height: 30px;
    padding: 4px 8px;
  }

  .radio-field .fields label {
    padding: 4px 10px;
  }

  .fligt-confirm .route-timing p {
    display: grid;
  }

  .select-state .fields {
    width: 100%;
  }

  .booking-timimg-main .route-timing .cunsume-time {
    display: none;
  }

  .fligt-confirm .booking-timing .route-strating {
    width: 50%;
  }

  .booking-page-right .fight-name {
    flex-wrap: wrap;
  }

  .booking-flight-half .flight-choose {
    flex-direction: column;
  }

  .menu,
  .mobile-menu {
    top: 40px;
  }

  /* add by jyotindra */
  .travel-detail-new .route-timing .flight-choose,
  .travel-detail-new .fligt-confirm .booking-timing .route-strating {
    width: 33.33%;
    padding: 0;
  }

  .booking-page-right.travel-detail-new .fligt-confirm .route-timing {
    gap: 20px;
  }

  .booking-page-right.travel-detail-new .fight-name {
    flex-wrap: nowrap;
  }

  .travel-detail-new .cunsume-time {
    width: 100%;
    padding-top: 15px;
  }

  .trip-detail-tabbing.flight-detail.table table {
    overflow-x: auto;
    display: block;
    padding: 0;
  }

  .trip-detail-tabbing.flight-detail.table {
    overflow-x: hidden;
  }
}

@media (max-width: 767px) {
  .login-modal .modal-dialog {
    flex-direction: column;
    width: 100%;
  }

  .modal-left,
  .login-modal .modal-content {
    width: 100%;
    border-radius: 20px;
    height: 350px;
    padding: 30px;
  }

  .place-field-dropdown.place-field-1 .place-field-pic {
    display: none;
  }

  .modal-left {
    border-radius: 20px 20px 0px 0px;
    margin-bottom: -16px;
  }

  div#exampleModal {
    padding: 10px !important;
  }

  .menu,
  .mobile-menu {
    width: 300px;
  }

  /* .travel-form-detail {
    margin: 0px 15px;
  } */

  .profile-page .row {
    gap: 25px;
  }

  .profile-page {
    padding: 25px 0px;
  }

  .profile-upload span {
    max-width: 220px;
    height: 100%;
    margin: 0 auto;
  }

  /* .profile-upload {
    margin: 0 auto;
  } */
  div#exampleModal {
    padding: 0px !important;
  }

  .login-modal .modal-dialog {
    padding: 0px 15px;
    max-width: 100%;
  }

  .slick-list {
    z-index: 1;
  }

  .modal-left,
  .login-modal .modal-content {
    width: 100%;
    border-radius: 20px;
    height: 290px;
    padding: 30px 15px;
  }

  .modal-left {
    height: 230px;
  }

  .login-modal .modal-body {
    height: 40px;
    padding: 12px 16px 5px 16px;
  }

  .cYwnDu {
    margin-top: 16px;
    font-size: 15px;
    padding: 8px 30px;
  }

  .traveller-detail {
    padding: 20px 15px 25px 15px;
  }

  span.note {
    border-radius: 30px;
    padding: 9px 15px;
    line-height: 15px;
  }

  .traveller-detail .field-set .fields,
  .radio-field {
    width: 100%;
    padding: 0px 10px;
  }

  .radio-field .fields {
    padding: 0px 0px !important;
  }

  .traveller-detail .field-set .fields.code-field {
    max-width: 100%;
  }

  .Booking-page .row {
    gap: 30px;
  }

  .traveller-detail a.fill-btn {
    font-size: 15px;
    line-height: 40px;
  }

  .travel-form-detail span.close-btn:before {
    display: none;
  }

  /* Add by jyotindra */

  .booking-page-right.travel-detail-new .fligt-confirm {
    padding: 20px 10px;
  }

  .traveller-list {
    width: 100%;
  }

  .travel-contact {
    flex-wrap: wrap;
  }

  .route-timing.triproute-timing {
    gap: 10px;
    flex-wrap: wrap;
  }

  .route-timing.triproute-timing .flight-choose {
    width: 100%;
    text-align: left;
  }

  .route-timing.triproute-timing .flight-choose .fight-name {
    justify-content: flex-start;
  }

  .btn-trip-complte {
    width: 100%;
    text-align: left;
  }

  .trip-detail-tabbing .nav-pills .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .trip-detail-tabbing .nav-pills .nav-link svg {
    /* jyotindra */
    display: block;
    margin-bottom: 4px;
  }

  .trip-detail-tabbing .nav-pills {
    gap: 15px;
  }

  .trip-detail-tabbing .nav-pills .nav-link {
    padding-top: 0;
  }

  .trip-detail-tabbing th {
    white-space: nowrap;
  }

  .trip-detail-tabbing th,
  .trip-detail-tabbing td {
    font-size: 14px;
  }

  .triproute-timing .route-strating {
    width: 47%;
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .col-lg-7 .place-field-dropdown {
    margin: 0px 6px;
  }

  span.switch img {
    max-width: 17px;
  }

  span.switch {
    width: 25px;
    height: 25px;
  }

  .place-field-text p {
    font-size: 10px;
  }

  /* added by jyotindra */

  .booking-page-right.travel-detail-new .fligt-confirm .route-timing {
    flex-wrap: wrap;
    padding: 10px 0px;
  }

  .travel-detail-new .route-timing .flight-choose {
    width: 100%;
  }

  .trip-detail-tabbing .nav-pills .nav-link {
    font-size: 13px;
  }
}
